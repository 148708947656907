import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import {Form} from 'react-bootstrap'
import {  useDispatch } from 'react-redux';
import { 
  addPlatform
} from './../../../../redux';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));
 
const ProductsToolbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [platformState, setPlatformState] = React.useState({
    dialog : false,
    name : '',
    url : '',
    image : []
  });

  const handleChange = name => event => {
    setPlatformState({...platformState, [name]: event.target.value})
  }

  const handleClickOpen = () => {
    setPlatformState({...platformState, dialog : true});
  };

  const handleClose = () => {
    setPlatformState({...platformState, dialog : false});
  };

  const dispatch = useDispatch()




  return (
  <React.Fragment>

      <Dialog open={platformState.dialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        
        <DialogContent>
          <DialogContentText>
            <Box pt={2} fontSize={25}>Add Platform</Box>
          </DialogContentText>
          <TextField
            autoFocus
            onChange={handleChange('name')}            
            id="name"
            label="Platform Name"
            type="text"
            fullWidth
          />

          <TextField          
          margin="dense"
          onChange={handleChange('url')}            
          id="url"
          label="URL Platform"
          type="text"
          fullWidth
        />
        <Box pt={2}>
        <Form.Group>
          <Form.File
            onChange={(e) => setPlatformState({
              ...platformState,
              image: e.target.files[0]
            })}
            id="image" 
            name="image" 
            label="Platform Image" 
          />
        </Form.Group>
        </Box>

        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose} color="danger">
            Cancel
          </Button>
          <Button 
            variant='contained' 
            onClick={() => dispatch(addPlatform(platformState))} 
            color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>        
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        >
          Add Platform
        </Button>
      </div>    
    </div>
    </React.Fragment>
  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductsToolbar;
