export const FETCH_PLATFORM_REQUEST = 'FETCH_PLATFORM_REQUEST'
export const FETCH_PLATFORM_SUCCESS = 'FETCH_PLATFORM_SUCCESS'
export const FETCH_PLATFORM_FAILED = 'FETCH_PLATFORM_FAILED'

export const ADD_PLATFORM_REQUEST = 'ADD_PLATFORM_REQUEST'
export const ADD_PLATFORM_FAILED = 'ADD_PLATFORM_FAILED'

export const UPDATE_PLATFORM_REQUEST = 'UPDATE_PLATFORM_REQUEST'
export const UPDATE_PLATFORM_FAILED = 'UPDATE_PLATFORM_FAILED'

export const DELETE_PLATFORM_REQUEST = 'DELETE_PLATFORM_REQUEST'
export const DELETE_PLATFORM_FAILED = 'DELETE_PLATFORM_FAILED'