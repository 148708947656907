import React, { useState } from 'react';
import { withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Axios from "axios";
import { API } from '../../config'
import * as SecureLS from 'secure-ls';
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/signup.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    
  },
  logoWhite: {
    top: 10,
    [theme.breakpoints.up('md')]: {   
      width: '100px'
     },
     [theme.breakpoints.up('lg')]: {   
      width: '130px'
     },
     [theme.breakpoints.up('xl')]: {
      width: '150px',
      top: '10'
     }
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    borderRadius: '15px'
  },
  customInputLabel: {
    "& legend": {
      visibility: "visible"
    }
  }
}));

const SignIn = props => {
  const ls = new SecureLS();
  const classes = useStyles();

  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [values, setValues] = useState({   
    email : '', 
    password: '',    
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeValue = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  
  const handleSignIn = (event) => {
    event.preventDefault();
    setLoadingSubmit(true)
    setTimeout(async() => {
      try {
        let formdata = new FormData();    
        formdata.append("email", values.email);
        formdata.append("password", values.password);
        const response = await Axios.post(`${API}/auth`, formdata)
        .then((res) => {     
          console.log(res.data , 'responsee')      
          if(res.data.data && res.data.data.auth.is_admin === 'true') {
            ls.set('_%adcp%', {data: res.data.data.auth.auth.token}); 
            ls.set('_%eml%', {data: res.data.data.auth.email}); 
            ls.set('_%nma%', {data: res.data.data.auth.fullname});
            // localStorage.setItem('nama', res.data.data.auth.fullname)                
            // localStorage.setItem('email', res.data.data.auth.email)                
            // localStorage.setItem('token', res.data.data.auth.auth.token)
            if(res.data.data.auth.company_id === null) {
              ls.set('_%role%', {data: "Super Admin"});
              // localStorage.setItem('spa', 'Super Admin')
              // localStorage.setItem('sp', '7GH12AS')
            } else {
              ls.set('_%role%', {data: "Admin"});
              // localStorage.setItem('spa', 'Admin')
              // localStorage.setItem('sp', 'RSFAG45')
            }            
            swal("Success", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              window.location.href = "/dashboard"
            }, 1500);
            setLoadingSubmit(false)
          } else {
            swal("Something Wrong!", `${res.data.message}`, "error");
            setLoadingSubmit(false)
          }         
        }).catch((err) => {
            swal("Something Wrong!", `${err.response.data.message}`, "error");
            setLoadingSubmit(false)
        })
        return response;
      } catch (error) {
        swal("Something Wrong!", `${error}`, "error");
        setLoadingSubmit(false)
      }
    }, 400)
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={6}
        >
          <div className={classes.quote}>             
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
           
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h1"
                >
                  LOGIN ADMIN
                </Typography>
                {/* {redirectUser()} */}
                
                <TextField
                  fullWidth
                  style={{marginBottom: '15px'}}
                  label="Email Address"
                  name="email"
                  onChange={handleChangeValue}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  required
                />
                <OutlinedInput
                  id="outlined-adornment-password"
                  classes={{ notchedOutline: classes.customInputLabel }}
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  name="password"
                  onChange={handleChangeValue}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  placeholder="Password"
                  fullWidth
                  notched
                  labelWidth={70}
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  { loadingSubmit ? <CircularProgress style={{color:'#fff', marginLeft: '20px', marginRight: '20px'}} size={23} /> : "SIGN IN" }
                </Button>
                
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(SignIn);
