import * as  SecureLS from 'secure-ls';

const ls = new SecureLS();

// GET DATA FROM LOCAL STORAGE
let token = ls.get('_%adcp%')
export const UserToken = token.data

let email = ls.get('_%eml%')
export const UserEmail = email.data

let name = ls.get('_%nma%')
export const UserName = name.data

let role = ls.get('_%role%')
export const UserRole = role.data

// console.log(UserToken, 'UserToken')
// console.log(UserEmail, 'UserEmail')
// console.log(UserName, 'UserName')
// console.log(UserRole, 'UserRole')

export const API = process.env.REACT_APP_API_URL
export const STORE = process.env.REACT_APP_API_URL_STORE

