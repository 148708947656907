import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Paper,
  Box,
  Divider,
  Button
} from '@material-ui/core';

import Axios from "axios";
import { API, UserToken } from '../../../../config'
import TextField from '@material-ui/core/TextField'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Form} from 'react-bootstrap' 
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '70px',
    height: '70px',
    borderRadius: '15px'
  },
  heightPaper: {
    height: '400px'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  }
}));

const ProductCard = props => {

  const classes = useStyles();

  const [dataPlatform, setDataPlatform] = useState([]);
  const [platformChild, setPlatformChild] = useState({
    name: "",
    platform_id: "",
    dialog: false
  });
  
    

  useEffect(()=>{    
    const loadPlatform = () => {
        // setLoading(true)
            Axios.get(`${API}/admin/platform?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setDataPlatform(res.data.results.data)   
                // console.log(res.data, 'res.data')           
                // setLoading(false)
            }).catch((err) => {
                console.log(err)
                // setLoading(false)
            })        
    }
    loadPlatform()
  }, []);

 
  const onSubmitChild = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        name: platformChild.name,
        platform_id: platformChild.platform_id,
      }
      const response = await Axios.post(`${API}/admin/platform/child`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {
            Axios.get(`${API}/admin/platform?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setDataPlatform(res.data.results.data)    
            }).catch((err) => {
                swal("Something Wrong!", `${err}`, "error");
            })
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              setPlatformChild({...platformChild, dialog: false})
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  return (
    <>
    <Grid container spacing={2}>
    { dataPlatform && dataPlatform.map((data, index) => {
      return (
      <Grid item lg={3} key={index}>

        <Paper elevation={3} className={classes.heightPaper} >
          <Box p={2}>
            <Grid container direction='column' alignItems="center" >
              <Box pb={2} fontSize={25} fontWeight="bold">
                {data.platform_name}
              </Box>

              <Box >
                <img
                  alt="Product"
                  className={classes.image}
                  src={data.icon_url}
                />
              </Box>

              <Divider/>
              <Box pt={2} fontSize={17} fontWeight="bold">
                PLATFORM CHILD :
              </Box>

              {data.child.map((da, inde) => (
                <Box key={inde} pt={1} fontSize={15}>
                  {da.name}
                </Box>
              ))}

              <Box pt={4} >
                <Button 
                  variant="contained" color="secondary" size="small"
                  onClick={() => setPlatformChild({...platformChild, platform_id: data.id, dialog: true})}
                  
                  >Add Platform Child</Button>
              </Box>

            </Grid>
          </Box>          
        </Paper>  
      </Grid>  
      )
    })}
    </Grid>

    {/* Start Modal Register */}
    <Dialog 
      open={platformChild.dialog} 
      onClose={() => setPlatformChild({...platformChild, dialog: false})} 
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
        <Form onSubmit={onSubmitChild} validate >
          <DialogTitle id="form-dialog-title">Add Platform Child</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Platform Child Name"
              type="text"
              variant="outlined"
              fullWidth
              onChange={(e) => setPlatformChild({...platformChild, name: e.target.value})}
              required
            />            
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPlatformChild({...platformChild, dialog: false})} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Register */}
  </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string
};

export default ProductCard;
