import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { 
  Button, 
  Box,
  Grid, 
  Card, 
  Typography,
  CardContent,
  Avatar,
  CircularProgress 
} from '@material-ui/core';
import emptysearch from './../../../../assets/img/emptysearch.svg'
import notselected from './../../../../assets/img/notselected.svg'
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Axios from "axios";
import { API, UserToken } from '../../../../config'
import Pagination from '@material-ui/lab/Pagination';
import DialogTitle from '@material-ui/core/DialogTitle'
import swal from 'sweetalert'
import millify from 'millify'
import DatePicker from 'react-date-picker';
import { remove, sumBy } from 'lodash'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  
  input: {
    display: 'none'
  },
  imgPreview: {
    width: '50%',
    height: '50%',
    paddingTop: '10px',
    paddingBottom: '10px'

  },
  infoImage: {
    fontSize: '10px',
    textAlign: 'center',
    paddingBottom: '15px'
  },
  cardContent: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  table: {
    minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  AvatarSize: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    borderRight: '2px solid #ef8745',
    borderLeft: '2px solid #c24770',
    borderTop: '2px solid #c24770',
    borderBottom: '2px solid #ef8745',
  },
  AvatarSizeOne: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    borderRight: '2px solid #ef8745',
    borderLeft: '2px solid #c24770',
    borderTop: '2px solid #c24770',
    borderBottom: '2px solid #ef8745',
  },
  ClickHere: {
    textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '20px',
    borderRadius: '10px'
  },
  selectInfluencer: {
    borderRight: '3px solid #ef8745',
    borderLeft: '3px solid #c24770',
    borderTop: '3px solid #c24770',
    borderBottom: '3px solid #ef8745',
    borderRadius: '15px', 
    paddingTop: '5px',
    paddingBottom: '7px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginRight: '10px'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateCampaign = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  
  const [searchedInfluencer, setSearchedInfluencer] = useState([])
  const [searchedInfluencerTotal, setSearchedInfluencerTotal] = useState([])
  const [searchedLoaded, setSearchedLoaded] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  
  const [interestData, setInterestData] = useState([])  
  const [dataPlatform, setDataPlatform] = useState([]);
  const [dataPlatformChild, setDataPlatformChild] = useState([]);
  
  const [inputSearch, setInputSearch] = useState({
    platform_child_id: "",
    min_follower: "",
    fullname: ""
  })

  const [createCampaign, setCreateCampaign] = useState({
    project_name: '', 
    client_id:'',
    owner_brand: '',
    owner_email: '',
    owner_contact: '',
    description: '',
    platform: '',
    payment_type: '',
    caption: '',
    type_of_post: '',
    status: '',
    content_type: '',
    flash_sale_start: '',
    flash_sale_end: '',
    expire_date:"",
    dialog: false
  })

  const [selectInfluencer, setSelectInfluencer] = useState([])

  const [findInfluencer, setFindInfluencer] = useState({
    modal: false
  })

  const [page, setPage] = React.useState(0);

  const handleChangePaginate = (data, value) => {
    setPage(value);
    onClickPaginate(value)
  };

  const [displayInput, setdisplayInput] = useState(true)

  // IMAGE 1
  const [selectedPhoto, setSelectedPhoto] = useState()
  const [previewPhoto, setPreviewPhoto] = useState()

  // console.log(selectedPhoto, 'previwe 1')
  useEffect(() => {
      if (!selectedPhoto) {
          setPreviewPhoto(undefined)
          return
      }
      const uploadImage = async() => {
        try {
          let formdata = new FormData();          
          formdata.append("file", selectedPhoto);
          formdata.append("email", 'admin@mediacartz.com');
          formdata.append("password", 123456);
          
          const uploadimg = await Axios.post(`https://cdn.mediacartz.com/api/store`, formdata)
          .then((res) => {                
              let url = res.data.data.url 
              setPreviewPhoto(url)
          }).catch((err) => {
              swal("Something Wrong!", `${err}`, "error");
          })  
          return uploadimg  
        } catch (error) {
          swal("Something Wrong!", `${error}`, "error");
        }
      }
      uploadImage()
  }, [selectedPhoto])

  const onSelectFile1 = e => {
      if (!e.target.files || e.target.files.length === 0) {
          setSelectedPhoto(undefined)
          return
      }
      setSelectedPhoto(e.target.files[0])
      setdisplayInput(false)
  }

  useEffect(()=>{       
    const loadInterest = async () => {
      try {
        const response = await Axios.get(`${API}/admin/interest/gets`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setInterestData(res.data.results)     
        }).catch((err) => {
            swal("Something Wrong!", `${err}`, "error");
        }) 
        return response;
      } catch (error) {
        swal("Something Wrong!", `${error}`, "error");
      } 
    }    
    const loadPlatform = async () => {
      try {
        const response = await Axios.get(`${API}/admin/platform/gets`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setDataPlatform(res.data.results)         
        }).catch((err) => {
            swal("Something Wrong!", `${err}`, "error");
        }) 
        return response;
      } catch (error) {
        swal("Something Wrong!", `${error}`, "error");
      }       
    }

    loadPlatform()
    loadInterest()


  }, []);


  const onSubmitCampaign = () => {
    // console.log(createCampaign, 'createCampaign')
    setLoadingSubmit(true)
    if(selectInfluencer.length !== 0) {
      let influencer = []
      
      for(let i = 0; i < selectInfluencer.length; i++) {
        influencer.push({
          user_id: selectInfluencer[i].user_id,
          start_date: moment(selectInfluencer[i].posting_start_date).format("YYYY-MM-DD"),
          end_date: moment(selectInfluencer[i].posting_end_date).format("YYYY-MM-DD")
        })
      }
      setTimeout(async() => {
        try {      
          let Headers = {
            headers: {                    
              Authorization: `Bearer ${UserToken}`
            }
          }
          let body = {
            platform_child_id : inputSearch.platform_child_id,
            project_name : createCampaign.project_name,
            owner_brand : createCampaign.owner_brand,
            owner_email : createCampaign.owner_email,
            owner_contact : createCampaign.owner_contact,
            open_bid_budget : 0,
            content_by_influencer : "false",
            payment_type : createCampaign.payment_type ,
            is_openbid : "false",
            description : createCampaign.caption,
            link_content : previewPhoto,
            order_desc : createCampaign.description,
            expire_date : moment(createCampaign.expire_date).format("YYYY-MM-DD"),
            interest_id : createCampaign.interest,
            preview_fee : "false",
            influencer : influencer
          }     
          const response = await Axios.post(`${API}/admin/order`, body, Headers)
            .then((res) => {
              swal("Success", `${res.data.message}`, "success");
              setLoadingSubmit(false)
              setTimeout(() => {
                swal.close()
                window.location.reload()
              },2000)
            }).catch((err) => {
              swal("Something Wrong!", `${err.response.data.message}`, "error");
              setLoadingSubmit(false)
            })
          return response;
        } catch (error) {
          swal("Something Wrong!", `${error}`, "error");
          setLoadingSubmit(false)
        }
      }, 500);

    } else {
      swal("Something Wrong!", "You are not selected Influencer yet!", "error");
      setLoadingSubmit(false)
    }
  }

  
  const onClickPaginate = async (value) => {
    setTimeout(async() => {
      if(inputSearch.platform_child_id === "") {
        swal("Something Wrong!", "I already told you! Type Posting is requred!", "error")
      } else {
        try {
          const response = await Axios.get(`${API}/admin/order/influencer`, {
            headers: {
              Authorization: `Bearer ${UserToken}`
            },
            params: {
              page: value,
              rows: 12,
              platform_child_id: inputSearch.platform_child_id,
              min_follower: inputSearch.min_follower,
              fullname: inputSearch.fullname
            }
          })
          .then((res) => {
              let resData = res.data.data.data
              let state = []
              let initial = false
              if(res.data.data) {
                if(selectInfluencer.length === 0) {
                  for( let i = 0; i < resData.length; i++ ) {
                    state.push({
                      user_id : resData[i].id,
                      platform_child: resData[i].platform_child,
                      fullname: resData[i].fullname,
                      username: resData[i].username,
                      influencer_image: resData[i].influencer_image,
                      post_price: resData[i].post_price,
                      followers: resData[i].followers,
                      color: "white"
                    })
                  }
                } else {
                  for( let i = 0; i < resData.length; i++ ) {
                    for(let e = 0; e < selectInfluencer.length; e++) {
                      if(resData[i].id === selectInfluencer[e].user_id) {
                        state.push({
                          user_id : resData[i].id,
                          platform_child: resData[i].platform_child,
                          fullname: resData[i].fullname,
                          username: resData[i].username,
                          influencer_image: resData[i].influencer_image,
                          post_price: resData[i].post_price,
                          followers: resData[i].followers,
                          color: "#363d87"
                        })
                        initial = true
                      }
                    }
                    if(!initial) {
                      state.push({
                        user_id : resData[i].id,
                        platform_child: resData[i].platform_child,
                        fullname: resData[i].fullname,
                        username: resData[i].username,
                        influencer_image: resData[i].influencer_image,
                        post_price: resData[i].post_price,
                        followers: resData[i].followers,
                        color: "white"
                      })
                    }
                    initial = false
                  }
                }
              }
              setSearchedInfluencer(state)
              setSearchedInfluencerTotal(res.data.data)
              setSearchedLoaded(true)
          }).catch((err) => {
              swal("Something Wrong!", `${err.response.data.message}`, "error");
          })    
          return response
        } catch (error) {
          swal("Something Wrong!", `${error}`, "error");
        }
      }      
    }, 1000);
  }

  const onClickSearchInfluencer = () => {
    setLoadingSearch(true)
    setTimeout(async() => {
      if(inputSearch.platform_child_id === "") {
        swal("Something Wrong!", "I already told you! Type Posting is requred!", "error");
        setLoadingSearch(false)
      } else {
        try {
          const response = await Axios.get(`${API}/admin/order/influencer`, {
            headers: {
              Authorization: `Bearer ${UserToken}`
            },
            params: {
              page: 1,
              rows: 12,
              platform_child_id: inputSearch.platform_child_id,
              min_follower: inputSearch.min_follower,
              fullname: inputSearch.fullname
            }
          })
          .then((res) => {
            let resData = res.data.data.data
            if(resData.length !== 0) {
              let state = []
            let initial = false
            if(res.data.data) {
              if(selectInfluencer.length === 0) {
                for( let i = 0; i < resData.length; i++ ) {
                  state.push({
                    user_id : resData[i].id,
                    platform_child: resData[i].platform_child,
                    fullname: resData[i].fullname,
                    username: resData[i].username,
                    influencer_image: resData[i].influencer_image,
                    post_price: resData[i].post_price,
                    followers: resData[i].followers,
                    color: "white"
                  })
                }
              } else {
                for( let i = 0; i < resData.length; i++ ) {
                  for(let e = 0; e < selectInfluencer.length; e++) {
                    if(resData[i].id === selectInfluencer[e].id) {
                      state.push({
                        user_id : resData[i].id,
                        platform_child: resData[i].platform_child,
                        fullname: resData[i].fullname,
                        username: resData[i].username,
                        influencer_image: resData[i].influencer_image,
                        post_price: resData[i].post_price,
                        followers: resData[i].followers,
                        color: "#363d87"
                      })
                      initial = true
                    }
                  }
                  if(!initial) {
                    state.push({
                      user_id : resData[i].id,
                      platform_child: resData[i].platform_child,
                      fullname: resData[i].fullname,
                      username: resData[i].username,
                      influencer_image: resData[i].influencer_image,
                      post_price: resData[i].post_price,
                      followers: resData[i].followers,
                      color: "white"
                    })
                  }
                  initial = false
                }
              }
            }
            
              setSearchedInfluencer(state)
              setSearchedInfluencerTotal(res.data.data)
              setSearchedLoaded(true)
              setLoadingSearch(false)    
              setPage(1)
            } else {
              swal("Something Wrong!", "Influencer not found, try with another type posting!", "error");
              setLoadingSearch(false)    
            }
          }).catch((err) => {
              swal("Something Wrong!", `${err.response.data.message}`, "error");
              setLoadingSearch(false)  
          })    
          return response
        } catch (error) {
          swal("Something Wrong!", `${error}`, "error");
          setLoadingSearch(false)  
        }
      }      
    }, 500);
  }


  const handleChangCreate = project_name => event => {
    setCreateCampaign({...createCampaign, [project_name]: event.target.value})
  }

  const handleClickAddd = () => {
    setCreateCampaign({...createCampaign, dialog: true});
  };

  const handleCloseAdd = () => {
    setCreateCampaign({...createCampaign, dialog: false});
  };

  const [providerContent, setProviderContent] = React.useState('');

  const handleProviderContent = (event, data) => {
    setProviderContent(data);
  };

  const handleChangePlatform = async(data) => {
    try {      
      let Headers = {
        headers: {                    
          Authorization: `Bearer ${UserToken}`
        }
      }     
      const response = await  Axios.get(`${API}/admin/platform/child/gets?platform_id=${data}`, Headers, null)
        .then((res) => {
            setDataPlatformChild(res.data.results)
            setSelectInfluencer([])
            setSearchedInfluencer([])
        }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
        })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  };

  const renderInterest = () => {
    if(interestData) {
      return (
        <>
        <option>Choose Interest</option>
        {interestData.map(data => (
          <option
            key={data.id}
            value={data.id}
          >
            {data.interest_name}
          </option>
          
        ))} 
        </>
      )
    }
  }
  
  const renderPlatform = () => {
    if(dataPlatform) {
      return (
        <>
        <option>Choose Platform</option>
        {dataPlatform.map(data => (
          <option
            key={data.id}
            value={data.id}
          >
            {data.platform_name}
          </option>
          
        ))} 
        </>
      )
    }
  }

  const renderPlatformChild = () => {
    if(dataPlatformChild) {
      return (
        <>
        <option>{dataPlatformChild.length !== 0 ? "Choose Type Posting" : "Choose Platform First"}</option>
        {dataPlatformChild.map(data => (
          <option
            key={data.id}
            value={data.id}
          >
            {data.name}
          </option>
          
        ))} 
        </>
      )
    }
  }

  const typePaymentData = [
    {
      id: 'post',
      name: 'Post'
    },      
    
  ]

  const renderTypePayment = () => {
    if(typePaymentData) {
      return (
        <>
        <option>Choose Type Post</option>
        {typePaymentData.map(data => (
          <option
            key={data.id}
            value={data.id}
          >
            {data.name}
          </option>
          
        ))} 
        </>
      )
    }
  }

  const renderPagination = () => {
    if(searchedInfluencerTotal) {     
      return (
      <Grid container direction="row" justify="flex-end"  >
        <Box pt={2} pb={2} >
          <Pagination count={searchedInfluencerTotal.lastPage} page={page} onChange={handleChangePaginate} color="primary" />
        </Box>      
      </Grid>
      )
    }
  }

 return (
  <>
    
    <Dialog 
      open={findInfluencer.modal}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
    >
        <DialogTitle>
          <Typography variant='h3' >Find Influencer</Typography>
        </DialogTitle>

        <DialogContent>
          <Box pb={1}>
           Type Posting is required to find influencer!
          </Box>
          
          <Grid container spacing={3}>
                
                <Grid item md={3} xl={3} xs={12} lg={3} >
                  <TextField
                    fullWidth
                    label="Type Posting"
                    margin="dense"
                    onChange={(e) => {
                      setInputSearch({...inputSearch, platform_child_id: e.target.value})
                      setSelectInfluencer([])
                      setSearchedInfluencer([])
                    }}
                    value={dataPlatformChild.id}
                    required
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                  >
                    {renderPlatformChild()}
                  </TextField>
                </Grid>

                <Grid item md={3} xl={3} xs={12} lg={3} >
                  <TextField
                    fullWidth
                    label="Name (optional)"
                    margin="dense"
                    onChange={(e) => setInputSearch({...inputSearch, fullname: e.target.value})}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={3} xl={3} xs={12} lg={3} >
                  <TextField
                    fullWidth
                    label="Min Followers (optional)"
                    type="number"
                    margin="dense"
                    onChange={(e) => setInputSearch({...inputSearch, min_follower: e.target.value})}
                    variant="outlined"
                  />
                </Grid>
                
                <Grid item md={3} xl={3} xs={12} lg={3} >
                  <Box pt={1} mr={2}>
                    <Button 
                      variant='contained' 
                      color="secondary" 
                      fullWidth
                      onClick={onClickSearchInfluencer}
                    >
                      { loadingSearch ? <CircularProgress style={{color:'#fff'}} size={27} /> : "Search" }
                    </Button>
                  </Box>
                </Grid>
          </Grid>          

          <Box pt={2}>
              { selectInfluencer.length !== 0 ?               
              <>
              <Box pb={1} fontSize={20} fontWeight="bold">Selected Influencer ({selectInfluencer.length}) </Box>
              <Box pb={1}>
                <Grid container direction='row'>
                  {selectInfluencer && selectInfluencer.map((data, index) => (
                    <Box className={classes.selectInfluencer} key={index}>
                      <Box fontWeight="bold" style={{textTransform: 'capitalize'}} >{data.fullname}</Box>
                    </Box> 
                  ))}
                </Grid>
              </Box>
              </>
              : ""
              }
              {searchedInfluencer.length !== 0 ? 
              <>
              <Box pt={2} mr={2}>
                <Box pb={2} fontSize={20} fontWeight="bold">Found({searchedInfluencerTotal.total}) </Box>
                <Grid container spacing={2}>
                  { searchedInfluencer.length !== 0 ? 
                    <>
                      {searchedLoaded && searchedInfluencer.map((data, index) => (
                        <Grid key={index} item lg={2} xl={2} md={2} >
                          <Paper elevation={3} 
                            style={{cursor: 'pointer', border:`3px solid ${data.color}`}}                            
                            onClick={() => {
                              let value = data.user_id
                              let newState = [...searchedInfluencer]

                              if(newState.find(a => a.user_id === value).color === "white") {
                                newState.find(a => a.user_id === value).color = "#363d87";
                                setSearchedInfluencer(newState)
                                setSelectInfluencer([...selectInfluencer, 
                                  {
                                    user_id : data.user_id,
                                    platform_child: data.platform_child,
                                    fullname: data.fullname,
                                    username: data.username,
                                    influencer_image: data.influencer_image,
                                    post_price: data.post_price,
                                    followers: data.followers,
                                    posting_start_date: "",
                                    posting_end_date: ""
                                  }
                                ])
                              } else {
                                newState.find(a => a.user_id === value).color = "white";
                                setSearchedInfluencer(newState)       
                                let array = [...selectInfluencer]  
                                let newArra = remove(array, function(n) {
                                  return n.user_id !== data.user_id;
                                })
                                setSelectInfluencer(newArra)                
                              }

                            }}
                          >
                            <Box p={2} >
                              <Grid  container direction="column" alignItems="center" justify="center" >  
                                <Box pb={1} fontSize={14}>{data.platform_child}</Box>
                                <Box>
                                  <Avatar alt="Influencer" 
                                    src={data.influencer_image} 
                                    className={classes.AvatarSize} 
                                  />
                                </Box>
                                <Box pt={1} fontSize={17} fontWeight="bold" style={{textTransform: 'capitalize'}}>{data.fullname}</Box>
                                <Box fontSize={13}>@{data.username}</Box>                              
                              </Grid>
                              <Box pt={1}>
                                  <Grid container direction='row' justify="space-around">
                                    <Box pb={1} textAlign="center" >
                                      <Box fontSize={15} fontWeight="bold">{millify(data.followers)}</Box>
                                      <Box fontSize={13}>Followers</Box>
                                    </Box>
                                    <Box textAlign="center">
                                      <Box fontSize={15} fontWeight="bold">Rp.{millify(data.post_price)}</Box>
                                      <Box fontSize={13}>Price</Box>
                                    </Box>
                                  </Grid>
                                </Box>
                            </Box>
                          </Paper>                        
                        </Grid>
                      ))}
                    </>
                    : 
                    <Grid container direction='column' alignItems="center">
                      <Box pt={8} fontWeight='bold'>Influencer not found, try with another platform.</Box>
                      <Box pb={2}> 
                        <img src={emptysearch} alt='empty state' width="300px" height="300px" />
                      </Box>
                    </Grid>
                  }
                  </Grid>
              </Box>

             { searchedInfluencerTotal.total > 12 ? 
              <Box pt={1} pb={1}>
                {renderPagination()}
              </Box> : ""
             }
              </>
              :
              <Grid container direction='row' justify="center">
                <Box pt={8} pb={4}> 
                  <img src={emptysearch} alt='empty state' width="300px" height="300px" />
                </Box>
              </Grid>
              }

          </Box>          
        </DialogContent>
        
        <DialogActions style={{paddingTop: '20px', paddingBottom: '20px', justifyContent: 'center'}}>
          <Button 
            color="default" variant="contained" 
            style={{marginRight: '10px'}}
            onClick={() => {
              setFindInfluencer({...findInfluencer, modal: false})
              setSearchedInfluencer([])
              setSearchedLoaded(false)
              setInputSearch({...inputSearch, 
                platform_child_id: "",
                min_follower: "",
                fullname: ""
              })
              setDataPlatformChild([])
              setSelectInfluencer([])
            }} 
          >
            Cancel 
          </Button>
          <Button 
            color="secondary" variant="contained" 
            onClick={() => {
              if(selectInfluencer.length !== 0) {
                setFindInfluencer({...findInfluencer, modal: false})
              } else {
                swal("Something Wrong!", "Please select Influencer First", "error");
              }
            }} 
          >
            Save 
          </Button>
        </DialogActions>
      </Dialog>

    <Dialog fullScreen  open={createCampaign.dialog} onClose={handleCloseAdd} aria-labelledby="form-dialog-title" TransitionComponent={Transition} >
        
      <DialogContent>
        <Card className={classes.cardContent}>
          <form autoComplete="off" noValidate >
            <Grid container spacing={2} >
              <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box pl={2} pt={3} >
                    <Box fontSize={20} fontWeight="bold">Basic Information </Box>
                  </Box>

                  <CardContent>
                    <Grid container spacing={3} >              

                      <Grid item md={12} xs={12} >
                        <TextField
                          fullWidth
                          label="Project Name"
                          margin="dense"
                          onChange={handleChangCreate('project_name')}
                          required
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={12} xs={12} >
                        <TextField
                          fullWidth
                          id="outlined-multiline-static"
                          label="Project Description"
                          margin="dense"
                          onChange={handleChangCreate('description')}
                          type='text'
                          variant="outlined"
                          multiline
                          rows={5}
                        />
                      </Grid>

                      <Grid item md={12} xs={12} >
                        <TextField
                          fullWidth
                          label="Interest"
                          margin="dense"
                          onChange={handleChangCreate('interest')}
                          value={interestData.id}
                          required
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                        >
                          {renderInterest()}
                        </TextField>
                      </Grid>

                      <Grid item md={12} xs={12} >
                        <TextField
                          fullWidth
                          label="Owner"
                          margin="dense"
                          onChange={handleChangCreate('owner_brand')}
                          type='text'
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={12} xs={12} >
                        <TextField
                          fullWidth
                          label="Owner Email"
                          margin="dense"
                          onChange={handleChangCreate('owner_email')}
                          type='text'
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={12} xs={12} >
                        <TextField
                          fullWidth
                          label="Owner Contact"
                          margin="dense"
                          onChange={handleChangCreate('owner_contact')}
                          type='text'
                          variant="outlined"
                        />
                      </Grid>
                      
                      <Grid item md={12} xs={12} > 
                        <TextField
                          fullWidth
                          label="Type Payment"
                          margin="dense"
                          onChange={handleChangCreate('payment_type')}
                          value={typePaymentData.id}
                          required
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                        >
                          {renderTypePayment()}
                        </TextField>
                      </Grid>

                      
                      <Grid item md={12} xs={12} >
                        <Box fontWeight={600} >Content Provider</Box>
                        
                        <Box pt={1} pb={1} >
                          <ToggleButtonGroup
                              value={providerContent}
                              exclusive
                              onChange={handleProviderContent}
                              aria-label="text alignment"
                              size='small'
                            >
                              <ToggleButton value="brand" aria-label="left aligned">
                                Brand
                              </ToggleButton>
                              {/* <ToggleButton value="influencer" aria-label="centered">
                                Influencer
                              </ToggleButton>                             */}
                          </ToggleButtonGroup>                        
                        </Box>

                      </Grid>      
                      
                      { providerContent === 'brand' ? 
                        <>
                          <Grid item md={12} xs={12} style={{ display: providerContent === 'influencer' ? 'none' : 'asd' }} >
                            <Box fontWeight={600} > &nbsp; Upload Image Content</Box>
                            <Box textAlign='center' pt={2} >
                                <input 
                                    accept="image/*" className={classes.input} id="icon-button-file" type="file"                      
                                    multiple
                                    onChange={onSelectFile1}
                                />

                                <label htmlFor="icon-button-file" style={{ display: displayInput ? 'asdfd' :  'none' }} >
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera style={{fontSize: '40px'}} />
                                  </IconButton>
                                </label>

                                { selectedPhoto && 
                                  <>
                                  <label htmlFor="icon-button-file">
                                  <img className={classes.imgPreview} alt='preview' src={previewPhoto} htmlFor="icon-button-file"  />
                                  </label>
                                    <Box className={classes.infoImage}>Click to change</Box>                            
                                  </>
                                }

                            </Box>
                          </Grid> 

                          <Grid item md={12} xs={12} style={{ display: providerContent === 'influencer' ? 'none' : 'asd' }} >
                            <TextField
                              fullWidth
                              id="outlined-multiline-static"
                              label="Content Caption"
                              margin="dense"
                              onChange={handleChangCreate('caption')}
                              type='text'
                              variant="outlined"
                              multiline
                              rows={5}
                            />
                          </Grid>
                        </> 
                        :
                        providerContent === 'influencer' ? '' : ''
                      }

                    </Grid>
                  </CardContent>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box pt={3} pb={2}  >
                    <Box fontSize={20} fontWeight="bold">Find Influencer </Box>
                  </Box>

                  <Box pt={1} pb={1} pr={2}>
                    <Grid container>
                      <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                      <TextField
                        fullWidth
                        label="Platform"
                        margin="dense"
                        onChange={(e) => handleChangePlatform(e.target.value)}
                        value={dataPlatform.id}
                        required
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                      >
                        {renderPlatform()}
                      </TextField>
                      </Grid>
                      <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box ml={1} pt={1}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() => {
                              if(dataPlatformChild.length !== 0) {
                                setFindInfluencer({...findInfluencer, modal: true})
                              } else {
                                swal("Something Wrong!", "Please choose Platform first!", "error");
                              }                              
                            }} 
                          >
                            Find Influencer 
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  
                  <Box pt={2}>
                    <Box pb={2} fontSize={20} fontWeight="bold">Selected Influencer </Box>
                    <Box mr={2}>
                    <Grid container spacing={2}>
                      {selectInfluencer.length !== 0 ? 
                      <>
                       {selectInfluencer.map((value, index) => (
                        <Grid item lg={12} xl={12} md={12} key={index}>
                        <Paper elevation={3}>
                          <Box p={2} >
                            <Grid container >
                              <Grid xl={2} lg={2} md={3} sm={6}>
                                <Grid container direction='column' alignItems="center" justify="center" >
                                  <Box>
                                    <Avatar alt="Influencer" 
                                      src={value.influencer_image} 
                                      className={classes.AvatarSizeOne} 
                                    />
                                  </Box>
                                  <Box 
                                    pt={1} 
                                    fontWeight="bold" 
                                    color="red" 
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      let val = value.user_id
                                      let newState = [...searchedInfluencer]

                                      if(newState.find(a => a.user_id === val).color === "white") {
                                        newState.find(a => a.user_id === val).color = "#363d87";
                                        setSearchedInfluencer(newState)                                        
                                      } else {
                                        newState.find(a => a.user_id === val).color = "white";
                                        setSearchedInfluencer(newState)      
                                        let array = [...selectInfluencer]  
                                        let newArra = remove(array, function(n) {
                                          return n.user_id !== value.user_id;
                                        })
                                        setSelectInfluencer(newArra)                    
                                      }
                                    }}
                                  >Remove</Box>
                                </Grid>
                              </Grid>
                              <Grid xl={3} lg={3} md={3} sm={6}>
                                <Grid container direction='column' >
                                  <Box textAlign="center">
                                    <Box pt={1} fontSize={18} fontWeight="bold" style={{textTransform: 'capitalize'}}>{value.fullname}</Box>
                                    <Box pb={1} fontSize={13}>@{value.username}</Box>
                                  </Box>
                                  <Grid container direction="row" justify="space-around">
                                    <Box pb={1} textAlign="center" >
                                      <Box fontSize={15} fontWeight="bold">{millify(value.followers)}</Box>
                                      <Box fontSize={13}>Followers</Box>
                                    </Box>
                                    <Box textAlign="center">
                                      <Box fontSize={15} fontWeight="bold">Rp.{millify(value.post_price)}</Box>
                                      <Box fontSize={13}>Price</Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid xl={7} lg={7} md={6} sm={12}>
                                <Box pl={2} pr={1} pt={2}>
                                <Grid container>
                                  <Grid item xl={6} lg={6} md={12} sm={6}>
                                    <Box pb={1}>Posting Start Date</Box>
                                    <div>
                                      <DatePicker
                                        onChange={(date) => {
                                          let today = new Date()
                                          if(date > today ) {
                                            let val = value.user_id
                                            let newState = [...selectInfluencer]
                                            newState.find(a => a.user_id === val).posting_start_date = date
                                            setSelectInfluencer(newState)
                                          } else {
                                            swal("Something Wrong!", "Date must be greater than today", "error");
                                          }
                                        }}
                                        value={value.posting_start_date}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xl={6} lg={6} md={12} sm={6} >
                                    <Box pb={1}>Posting End Date</Box>
                                    <div>
                                      <DatePicker
                                        onChange={(date) => {
                                          let posting_start = value.posting_start_date
                                          if(date > posting_start) {
                                            let val = value.user_id
                                            let newState = [...selectInfluencer]
                                            newState.find(a => a.user_id === val).posting_end_date = date
                                            setSelectInfluencer(newState)
                                          } else {
                                            swal("Something Wrong!", "Date must be greater than Start Posting Date", "error");
                                          }
                                        }}
                                        value={value.posting_end_date}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                                </Box>
                              </Grid>    
                              
                            </Grid>
                          </Box>                          
                        </Paper>                        
                      </Grid>
                       ))}
                      </>
                      : 
                      <Grid container direction='column' alignItems="center">
                        <Box pt={8} fontWeight='bold'>No selected Influencer.</Box>
                        <Box pb={2}> 
                          <img src={notselected} alt='empty state' width="300px" height="300px" />
                        </Box>
                      </Grid>                      
                      }                                            
                      
                    </Grid>
                    </Box>
                  </Box>

                  
                  <Box pt={3}>
                    <Grid container direction="row" justify="space-between">
                      <Box fontSize={20} fontWeight="bold">Total Project Price </Box>
                      <Box fontSize={20} pr={2} fontWeight="bold">Rp. {selectInfluencer.length !== 0 ? sumBy(selectInfluencer, function(e) {return e.post_price }).toLocaleString() : "" }</Box>
                    </Grid>
                  </Box>

                  <Box pt={3}>
                    <Grid container direction="row" justify="space-between">
                      <Box fontSize={20} fontWeight="bold">Expire Date </Box>
                      <div style={{marginRight: '15px'}}>
                        <DatePicker
                          onChange={(date) => {
                            let today = new Date()
                            if(date > today) {
                              setCreateCampaign({...createCampaign, expire_date: date})
                            } else {
                              swal("Something Wrong!", "Date must be greater than Today", "error");
                            }
                          }}
                          value={createCampaign.expire_date}
                        />
                      </div>
                    </Grid>
                  </Box>

              </Grid>

            </Grid>
          </form>
        </Card>
      </DialogContent>
          
          <DialogActions style={{justifyContent:'center', marginBottom: '10px', marginTop: '10px'}} >
              <Button onClick={handleCloseAdd} color="secondary" variant='contained'>
                Cancel
              </Button>
              <Button 
                color="primary" variant='contained' 
                onClick={onSubmitCampaign}
              >
               { loadingSubmit ? <CircularProgress style={{color:'#fff', marginLeft: '20px', marginRight: '20px'}} size={23} /> : "Submit Data" }
              </Button>
              
              </DialogActions>
            
      </Dialog>

    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickAddd}

        >
          Create Campaign
        </Button>
        <Box mr={2}  />
        
      </div>
    </div>

  </>
  );
};

CreateCampaign.propTypes = {
  className: PropTypes.string
};

export default CreateCampaign;
