import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import {Form} from 'react-bootstrap' 
import {  useDispatch } from 'react-redux';
import { 
  addInterest
} from './../../../../redux';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const InterestToolbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [interestState, setInterestState] = React.useState({
    dialog : false,
    name : '',
    image : []
  });

  
  const handleChange = name => event => {
    setInterestState({...interestState, [name]: event.target.value})
  }

  const handleClickOpen = () => {
    setInterestState({...interestState, dialog : true});
  };

  const handleClose = () => {
    setInterestState({...interestState, dialog : false});
  };

  const dispatch = useDispatch()

  return (
    <>

    <Dialog open={interestState.dialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        
        <DialogContent>
          <DialogContentText>
            <Box pt={2} fontSize={25}>Add Interest</Box>
          </DialogContentText>
          <TextField
            autoFocus
            onChange={handleChange('name')}            
            id="name"
            label="Interest Name"
            type="text"
            fullWidth
          />          
        <Box pt={2}>
        <Form.Group>
          <Form.File
            onChange={(e) => setInterestState({
              ...interestState,
              image: e.target.files[0]
            })}
            id="image" 
            name="image" 
            label="Interest Image" 
          />
        </Form.Group>
        </Box>

        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose} color="danger">
            Cancel
          </Button>
          <Button 
            variant='contained' 
            onClick={() => dispatch(addInterest(interestState))} 
            color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid container>
        <Grid item lg={2} md={4} sm={12} xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickOpen}

          >
            Add Interest
          </Button>
        </Grid>
      </Grid>
    </div>

    </>
  );
};

InterestToolbar.propTypes = {
  className: PropTypes.string
};

export default InterestToolbar;
