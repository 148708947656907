import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Divider
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import swal from 'sweetalert'
import Axios from "axios";
import { API, UserToken, UserRole } from "./../../config.js"
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const CampaignInfluencer = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();

  const [campaign, setcampaign] = useState([]);
  const [dataCampaign, setDatacampaign] = useState([]);
  const [detailCampaign, setDetailCampaign] = useState({
    id: "",
    project_name: "",
    owner_brand: "",
    owner_email: "",
    owner_contact: "",
    confirm_budget: "",
    description: "",
    link_content: "",
    payment_type: "",
    status: "",
    order_desc: "",
    expire_date: "",    
    start_date: "",    
    end_date: "",    
    platform_child: "",
    platform: "",
    price: "",
    influencer: "",
    email: "",    
    dialog: false
  })

  const [modalResponse, setModalResponse] = useState({
    id: "",
    order_influencer_id: "",
    project_name: "",
    owner_brand: "",
    owner_email: "",
    owner_contact: "",
    confirm_budget: "",
    description: "",
    link_content: "",
    payment_type: "",
    status: "",
    order_desc: "",
    expire_date: "",    
    start_date: "",    
    end_date: "",    
    platform_child: "",
    platform: "",
    price: "",
    influencer: "",
    email: "",    
    updatelink: "",
    content: [],
    response_approve: "",
    message_response: "",
    trx_id: "",
    dialog: false
  })

  const [statusChange, setStatusChange] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
    loadCampaignPaginate(page)
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const DataStatus = [
    {
        id: "",
        name: 'ALL'
    }, 
    {
        id: 'WAITING FOR OWNER',
        name: 'WAITING FOR OWNER'
    },  
    {
         id: 'WAITING FOR INFLUENCER',
        name: 'WAITING FOR INFLUENCER'
    },
    {
        id: 'ACCEPTED BY OWNER',
        name: 'ACCEPTED BY OWNER'
    },  
    {
        id: 'ACCEPTED BY INFLUENCER',
        name: 'ACCEPTED BY INFLUENCER'
    },   
    {
        id: 'NEGOTIATING',
        name: 'NEGOTIATING'
    },  
    {
        id: 'AGREE',
        name: 'AGREE'
    },    
    {
        id: 'RUNNING',
        name: 'RUNNING'
    },  
    {
        id: 'REJECTED BY OWNER',
        name: 'REJECTED BY OWNER'
    },    {
        id: 'REJECTED BY INFLUENCER',
        name: 'REJECTED BY INFLUENCER'
    },  
    {
        id: 'CONSOLIDATION',
        name: 'CONSOLIDATION'
    }, 
    {
        id: 'DONE',
        name: 'DONE'
    },    
    
  ]

  const renderStatus = () => {
    if(DataStatus) {
      return (
        <>        
        {DataStatus.map(data => (
          <option
            key={data.name}
            value={data.id}
          >
            {data.name}
          </option>
        ))} 
        </>
      )
    }
  }

  const handleChangeStatus = async(data) => {
    try {  
      const response = await Axios.get(`${API}/admin/order/influencer/gets?page=1&rows=10&status=${data}`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
          setcampaign(res.data.result.data)
          setDatacampaign(res.data.result)
        }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
        })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  };

  
  const loadCampaignPaginate = async (page) => {    
    let page_data = page + 1
    let rows_data = 10

    const response = await  Axios.get(`${API}/admin/order/influencer/gets?page=${page_data}&rows=${rows_data}&status${statusChange}`, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          setcampaign(res.data.result.data)
          setDatacampaign(res.data.result)
      }).catch((err) => {
          console.log(err)
      })
    return response;
  }

  
  useEffect(()=>{    
    const loadCampaign = () => {
      Axios.get(`${API}/admin/order/influencer/gets?page=1&rows=10`, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          setcampaign(res.data.result.data)
          setDatacampaign(res.data.result)
      }).catch((err) => {
          console.log(err)
      })        
    }
    loadCampaign()
  }, []); 

  const onSubmitAccept = () => {
    try {
      let body = {
        order_influencer_id: modalResponse.order_influencer_id,
        option : "accept"
      }
      Axios.post(`${API}/admin/order/influencer/response`, body, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          swal("Success", `${res.data.message}`, "success");
          setTimeout(() => {
            swal.close()
            setModalResponse({...modalResponse, dialog: false})
            window.location.reload()
          }, 500);
      }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
      })        
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");      
    }
  }

  const onSubmitReject = () => {
    try {
      let body = {
        order_influencer_id: modalResponse.order_influencer_id,
        option : "reject"
      }
      Axios.post(`${API}/admin/order/influencer/response`, body, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          swal("Success", `${res.data.message}`, "success");
          setTimeout(() => {
            swal.close()
            setModalResponse({...modalResponse, dialog: false})
            window.location.reload()
          }, 500);
      }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
      })        
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");      
    }
  }

  const onSubmitUpdateLink = () => {
    try {
      let body = {
        order_influencer_id: modalResponse.order_influencer_id,
        link_content : modalResponse.updatelink
      }
      Axios.post(`${API}/admin/order/influencer/content`, body, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          swal("Success", `${res.data.message}`, "success");
          setTimeout(() => {
            swal.close()
            setModalResponse({...modalResponse, dialog: false})
            window.location.reload()
          }, 500);
      }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
      })        
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");      
    }
  }

  const onSubmitReponseVerify = () => {
    try {
      let body = {
        order_influencer_id: modalResponse.order_influencer_id,
        trx_id : modalResponse.trx_id,
        action: modalResponse.response_approve,
        message: modalResponse.message_response
      }
      Axios.post(`${API}/admin/order/influencer/action`, body, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          swal("Success", `${res.data.message}`, "success");
          setTimeout(() => {
            swal.close()
            setModalResponse({...modalResponse, dialog: false})
            window.location.reload()
          }, 500);
      }).catch((err) => {
          swal("Something Wrong!", `${err.response.data.message}`, "error");
      })        
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");      
    }
  }


  const renderInfluencer = () => {
    if(campaign) {
      return (
        <>
        {campaign.map((data, index) => (
          <TableRow
            className={classes.tableRow}
            hover
            key={data.id}
          >
            <TableCell>
              { dataCampaign.page > 1 ? 
                  <>
                    { index + 1 + (dataCampaign.perPage  * (dataCampaign.page - 1) ) }
                  </>
                  : 
                  <>
                  {index  + 1}
                  </>
              }
            </TableCell>
            <TableCell>{data.order.company_id === null ? "MEDIACARTZ" : data.order.company.company }</TableCell>
            <TableCell
                style={{cursor: 'pointer', color: '#2558ab', fontWeight: 'bold', textDecoration: 'underline'}}
                onClick={() => setDetailCampaign({
                  ...detailCampaign, 
                  dialog: true,
                  id: data.order.id,
                  project_name: data.order.project_name,
                  owner_brand: data.order.owner_brand,
                  owner_email: data.order.owner_email,
                  owner_contact: data.order.owner_contact,
                  confirm_budget: data.order.confirm_budget,
                  description: data.order.description,
                  link_content: data.order.link_content,
                  payment_type: data.order.payment_type,
                  status: data.order.status,
                  order_desc: data.order.order_desc,
                  expire_date: data.order.expire_date,
                  start_date: data.start_date,
                  end_date: data.end_date,
                  platform_child: data.order.platform_child.name,
                  platform: data.order.platform_child.platform.platform_name,
                  price: data.price,
                  influencer: data.influencer.fullname,
                  email: data.influencer.email,  
                })}
            >{data.order.project_name}</TableCell>
            <TableCell>{data.order.owner_brand}</TableCell>
            <TableCell style={{textTransform: 'capitalize'}}>{data.influencer.fullname}</TableCell>
            <TableCell>{data.order.platform_child.name}</TableCell>
            <TableCell>Rp.{data.price.toLocaleString()}</TableCell>
            <TableCell>
            { data.status === "WAITING FOR OWNER" ?
                <Chip
                  size="small"
                  label="WAITING FOR OWNER"                  
                  style={{backgroundColor: '#f9ca00', color: 'white', width: '200px', textTransform: 'capitalize'}}
                />
                :
                data.status === "WAITING FOR INFLUENCER" ?
                <Chip
                  size="small"
                  label="WAITING FOR INFLUENCER"                  
                  style={{backgroundColor: '#f9ca00', color: 'white', width: '200px', textTransform: 'capitalize'}}
                /> 
                :
                data.status === "ACCEPTED BY OWNER" ?
                <Chip
                  size="small"
                  label="ACCEPTED BY OWNER"                  
                  style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                /> 
                :
                data.status === "ACCEPTED BY INFLUENCER" ?
                <Chip
                  size="small"
                  label="ACCEPTED BY INFLUENCER"                  
                  style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                /> 
                :
                data.status === "NEGOTIATING" ?
                <Chip
                  size="small"
                  label="NEGOTIATING"                  
                  style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                /> 
                : 
                data.status === "AGREE" ?
                <Chip
                  size="small"
                  label="AGREE"                  
                  style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                /> 
                : 
                data.status === "RUNNING" ?
                <Chip
                  size="small"
                  label="RUNNING"                  
                  style={{backgroundColor: '#7367f0', color: 'white', width: '200px'}}
                /> 
                : 
                data.status === "REJECTED BY OWNER" ?
                <Chip
                  size="small"
                  label="REJECTED BY OWNER"                  
                  style={{backgroundColor: '#e8505b', color: 'white', width: '200px'}}
                /> 
                : 
                data.status === "REJECTED BY INFLUENCER" ?
                <Chip
                  size="small"
                  label="REJECTED BY INFLUENCER"                  
                  style={{backgroundColor: '#e8505b', color: 'white', width: '200px'}}
                /> 
                : 
                data.status === "CONSOLIDATION" ?
                <Chip
                  size="small"
                  label="CONSOLIDATION"                  
                  style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                /> 
                :
                data.status === "VERIFY CONTENT" ?
                <Chip
                  size="small"
                  label="VERIFY CONTENT"                  
                  style={{backgroundColor: '#9742b2', color: 'white', width: '200px'}}
                /> 
                : 
                data.status === "REQUIRE REPOST CONTENT" ?
                <Chip
                  size="small"
                  label="REQUIRE REPOST CONTENT"                  
                  style={{backgroundColor: '#da6f4d', color: 'white', width: '200px'}}
                />
                : 
                <Chip
                  size="small"
                  label="DONE"                  
                  style={{backgroundColor: '#192131', color: 'white', width: '200px'}}
                />         
              }
            </TableCell>
            <TableCell >
              <Button 
                variant="contained"
                color="secondary"
                disabled={UserRole === 'Super Admin' ? true : false}
                onClick={() => setModalResponse({
                  ...modalResponse, 
                  dialog: true,
                  id: data.order.id,
                  order_influencer_id: data.id,
                  project_name: data.order.project_name,
                  owner_brand: data.order.owner_brand,
                  owner_email: data.order.owner_email,
                  owner_contact: data.order.owner_contact,
                  confirm_budget: data.order.confirm_budget,
                  description: data.order.description,
                  link_content: data.order.link_content,
                  payment_type: data.order.payment_type,
                  status: data.status,
                  order_desc: data.order.order_desc,
                  expire_date: data.order.expire_date,
                  start_date: data.start_date,
                  end_date: data.end_date,
                  platform_child: data.order.platform_child.name,
                  platform: data.order.platform_child.platform.platform_name,
                  price: data.price,
                  influencer: data.influencer.fullname,
                  content: data.contents,
                  trx_id: data.order.trx_id,
                  email: data.influencer.email,  
                })}
              >
                Response
              </Button>              
            </TableCell>       
          </TableRow>
        ))}
        </>
      )
    }
  }

  return (
    <>
    <Box pb={1} pt={2} pl={2}>    
        <TextField
            width="300px"
            margin="dense"
            onChange={(e) => {
                handleChangeStatus(e.target.value)
                setStatusChange(e.target.value)
            }}
            value={DataStatus.id}
            required
            select
            SelectProps={{ native: true }}
            variant="outlined"
        >
            {renderStatus()}
        </TextField>       
    </Box>
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="5px">No</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Influencer</TableCell>                  
                  <TableCell>Platform</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell width="120px">Response</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderInfluencer()}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={dataCampaign.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={10}
        />
      </CardActions>
    </Card>

    <Dialog
        open={detailCampaign.dialog}      
        fullWidth={true}
        maxWidth="sm"  
      >
        <DialogTitle>
          <h4>Detail Campaign</h4>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Project Name</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{detailCampaign.project_name}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Project Description</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{detailCampaign.order_desc}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Price</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>              
                <h6>Rp.{detailCampaign.price.toLocaleString()}</h6>             
            </Grid>
          </Grid>          
          <Box pt={1} pb={1}><Divider/></Box>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Owner</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{detailCampaign.owner_brand}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Owner Email</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{detailCampaign.owner_email}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Owner Contact</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{detailCampaign.owner_contact}</h6>
            </Grid>
          </Grid>
          <Box pt={1} pb={1}><Divider/></Box>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Influencer</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6 style={{textTransform: 'capitalize'}}>{detailCampaign.influencer}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Email</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6 style={{textTransform: 'capitalize'}}>{detailCampaign.email}</h6>
            </Grid>
          </Grid>

          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Platform</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6 style={{textTransform: 'capitalize'}}>{detailCampaign.platform}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Type Posting</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{detailCampaign.platform_child}</h6>
            </Grid>
          </Grid>
          <Box pt={1} pb={1}><Divider/></Box>
          
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Image Content</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <Box pt={1} pb={1}>
                  <img alt="link" src={detailCampaign.link_content} width="150px" />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Caption </h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{detailCampaign.description}</h6>
            </Grid>
          </Grid>
          <Box pt={1} pb={1}><Divider/></Box>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Start Date</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <h6>{moment(detailCampaign.start_date).format('DD MMMM YYYY ')}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>End Date</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <h6>{moment(detailCampaign.end_date).format('DD MMMM YYYY ')}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Expire Date</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <h6>{moment(detailCampaign.expire_date).format('DD MMMM YYYY ')}</h6>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setDetailCampaign({...detailCampaign, dialog: false})} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={modalResponse.dialog}      
        fullWidth={true}
        maxWidth="sm"  
      >
        <DialogTitle>
          <h4>Reponse</h4>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Project Name</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{modalResponse.project_name}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Price</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <h6>Rp.{modalResponse.price.toLocaleString()}</h6>
            </Grid>
          </Grid>          
          <Box pt={1} pb={1}><Divider/></Box>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Owner</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{modalResponse.owner_brand}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Owner Email</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{modalResponse.owner_email}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Owner Contact</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{modalResponse.owner_contact}</h6>
            </Grid>
          </Grid>
          <Box pt={1} pb={1}><Divider/></Box>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Influencer</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6 style={{textTransform: 'capitalize'}}>{modalResponse.influencer}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Email</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6 style={{textTransform: 'capitalize'}}>{modalResponse.email}</h6>
            </Grid>
          </Grid>

          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Platform</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6 style={{textTransform: 'capitalize'}}>{modalResponse.platform}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Type Posting</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
              <h6>{modalResponse.platform_child}</h6>
            </Grid>
          </Grid>
          <Box pt={1} pb={1}><Divider/></Box>
          
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Start Date</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <h6>{moment(modalResponse.start_date).format('DD MMMM YYYY ')}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>End Date</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <h6>{moment(modalResponse.end_date).format('DD MMMM YYYY ')}</h6>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Expire Date</h6>
            </Grid>
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <h6>{moment(modalResponse.expire_date).format('DD MMMM YYYY ')}</h6>
            </Grid>
          </Grid>
          <Box pt={1} pb={1}><Divider/></Box>
          {modalResponse.content.length !== 0 ? 
            <>
            <Grid container>
              <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Link Content</h6>
              </Grid>
              <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6><a href={modalResponse.content[0].link_content} target="_blank" rel="noreferrer" >{modalResponse.content[0].link_content}</a></h6>
              </Grid>
            </Grid> 
            {modalResponse.status === "REQUIRE REPOST CONTENT" ? 
            <Grid container>
              <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Message from Brand</h6>
              </Grid>
              <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{modalResponse.content[0].link_comment}</h6>
              </Grid>
            </Grid> : ""
            }
            </> 
            : ""
          }
          <Grid container>
            <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
              <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Action</h6>
            </Grid>
            { modalResponse.status === "WAITING FOR INFLUENCER" ? 
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <Grid container direction='row'>
                  <Box mr={1}>
                    <Button variant="contained" style={{backgroundColor: '#7fbbcc', color: 'white'}} size="small"
                      onClick={onSubmitAccept}
                    >
                      Accept
                    </Button>
                  </Box>
                  <Box>
                    <Button variant="contained" style={{backgroundColor: '#ee4054', color: 'white'}} size="small"
                      onClick={onSubmitReject}
                    >
                      Reject
                    </Button>
                  </Box>
                </Grid>
            </Grid> 
            : 
            modalResponse.status === "AGREE" ?
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <Grid container direction='row'>
                  <Box mr={1}>
                    <TextField
                      type="text"
                      label="Input your link"
                      variant="outlined"
                      size="small"
                      width="190px"
                      onChange={(e) => setModalResponse({...modalResponse, updatelink: e.target.value})}
                    />
                  </Box>
                  <Box>
                    <Button variant="contained" style={{backgroundColor: '#2558ab', color: 'white'}} size="middle"
                      onClick={onSubmitUpdateLink}
                    >
                      UPDATE LINK
                    </Button>
                  </Box>
                </Grid>
            </Grid>
            :
            modalResponse.status === "REQUIRE REPOST CONTENT" ?
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <Grid container direction='row'>
                  <Box mr={1}>
                    <TextField
                      type="text"
                      label="Input your link"
                      variant="outlined"
                      size="small"
                      width="190px"
                      onChange={(e) => setModalResponse({...modalResponse, updatelink: e.target.value})}
                    />
                  </Box>
                  <Box>
                    <Button variant="contained" style={{backgroundColor: '#2558ab', color: 'white'}} size="middle"
                      onClick={onSubmitUpdateLink}
                    >
                      UPDATE LINK
                    </Button>
                  </Box>
                </Grid>
            </Grid>
            :
            modalResponse.status === "VERIFY CONTENT" ?
            <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                <Grid container direction='column'>
                  <Box pb={1}>
                    <TextField
                        width="300px"
                        margin="dense"
                        onChange={(e) => setModalResponse({...modalResponse, response_approve: e.target.value})}
                        required
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                    >
                        <option>Choose Response</option>
                        <option value="accept">Approve</option>
                        <option value="reject">Reject</option>
                    </TextField>    
                  </Box>
                  <Box pb={1} >
                    <TextField
                      type="text"
                      label="Your Message"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => setModalResponse({...modalResponse, message_response: e.target.value})}
                    />
                  </Box>
                  <Box>
                    <Button variant="contained" style={{backgroundColor: '#2558ab', color: 'white'}} size="middle"
                      onClick={onSubmitReponseVerify}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
            </Grid>
            :
            <h6>No need Action</h6>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setModalResponse({...modalResponse, dialog: false})} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CampaignInfluencer.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default CampaignInfluencer;
