import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  Avatar,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
  Typography
 } from '@material-ui/core';
import {Form} from 'react-bootstrap' 

import TextField from '@material-ui/core/TextField'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Axios from "axios";
import { API, UserToken } from '../../../../config'
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
  root: {},
  rowTop : {
    paddingBottom: '20px'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  FormControl: {
    marginBottom: '20px'
  }
}));

const BasepriceTable = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();
  // initial state 
  const [baseprice, setBaseprice] = useState([]);
  const [dataBaseprice, setDataBaseprice] = useState([]);
  const [dataPlatform, setDataPlatform] = useState([]);
  const [dataPlatformChild, setDataPlatformChild] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [createBaseprice, setCreateBaseprice] = useState({
    platform_child_id: "",
    level: "",
    ppc_price: "",
    post_price: "",
    dialog: false
  });

  const [editBaseprice, setEditBaseprice] = useState({
    id: "",
    ppc_price: "",
    post_price: "",
    dialog: false
  });
 
  // register handler
  const handleClickRegister = () => {
    setCreateBaseprice({...createBaseprice, dialog: true});
  };

  const handleCloseCreate = () => {
    setCreateBaseprice({...createBaseprice, dialog: false});
  };

  const handleChangeCreate = platform_child_id => event => {
    setCreateBaseprice({...createBaseprice, [platform_child_id]: event.target.value})
  }

  // edit handler
  const handleCloseEdit = () => {
    setEditBaseprice({...editBaseprice, dialog: false});
  };

  const handleChangeEdit = ppc_price => event => {
    setEditBaseprice({...editBaseprice, [ppc_price]: event.target.value})
  }

  const onSubmitEdit = async (e) => {
    e.preventDefault()
    // console.log(editBaseprice, 'editBaseprice')
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        id: editBaseprice.id,
        post_price: editBaseprice.post_price,
        ppc_price: editBaseprice.ppc_price
      }
      const response = await Axios.put(`${API}/admin/baseprice`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {
            Axios.get(`${API}/admin/baseprice?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setBaseprice(res.data.results.data)
                setDataBaseprice(res.data.results)  
            }).catch((err) => {
                swal("Something Wrong!", `${err}`, "error");
            })
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              handleCloseEdit()
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  const onSubmitCreateBaseprice = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        platform_child_id: createBaseprice.platform_child_id,
        level: createBaseprice.level,
        ppc_price: createBaseprice.ppc_price,
        post_price: createBaseprice.post_price
      }
      const response = await Axios.post(`${API}/admin/baseprice`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {
            Axios.get(`${API}/admin/baseprice?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setBaseprice(res.data.results.data)
                setDataBaseprice(res.data.results)  
            }).catch((err) => {
                swal("Something Wrong!", `${err}`, "error");
            })
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              handleCloseCreate()
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  const deleteDataBaseprice = (state) => {

    swal({
      title: "Are you sure?",
      text: `You will delete data, once deleted you will not be able to recover the data!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        // Axios.delete(`${API}/admin/company`, {
        //   headers: {                    
        //     Authorization: `Bearer ${UserToken}`
        //   },
        //   data: {
        //     id: state.id
        //   }
        // })
        // .then((res) => {   
        //   if(res.data.status === "success") {
        //     Axios.get(`${API}/admin/company?page=1&rows=10`, {
        //       headers: {                    
        //           Authorization: `Bearer ${UserToken}`
        //         }
        //       })
        //       .then((res) => {
        //           setBaseprice(res.data.results.data)
        //           setDataBaseprice(res.data.results)  
        //       }).catch((err) => {
        //           swal("Something Wrong!", `${err}`, "error");
        //       })   

        //       swal("Poof! Data Company has been deleted!", {
        //         icon: "success",
        //       });   
        //     }     
        //   }).catch((err) => {
        //   })
          swal("Something Wrong!", "You are not authorized!", "error");
      } else {
        swal.close()  
        
      }
    });
  }

  const handlePageChange = (event, page) => {
    setPage(page);
    loadCompanyPaginate(page)
  };

  
  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  
  const loadCompanyPaginate = async (page) => {
    
    try {
      let page_data = page + 1
      let rows_data = 10

      const response = await  Axios.get(`${API}/admin/baseprice?page=${page_data}&rows=${rows_data}`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setBaseprice(res.data.results.data)
            setDataBaseprice(res.data.results)
        }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
        })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }

  }


  const handleChangePlatform = async(data) => {
    // console.log(data, 'data platform')
    try {      

      let Headers = {
        headers: {                    
          Authorization: `Bearer ${UserToken}`
        }
      }
     
      const response = await  Axios.get(`${API}/admin/platform/child/gets?platform_id=${data}`, Headers, null)
        .then((res) => {
            // console.log(res.data , 'ressss')
            setDataPlatformChild(res.data.results)
        }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
        })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  };

  
  useEffect(()=>{    
    const loadCompany = async () => {
        // setLoading(true)
        try {
          const response = await Axios.get(`${API}/admin/baseprice?page=1&rows=10`, {
            headers: {                    
                Authorization: `Bearer ${UserToken}`
            }
          })
          .then((res) => {
              // console.log(res.data, 'ressss')
              setBaseprice(res.data.results.data)
              setDataBaseprice(res.data.results)              
              // setLoading(false)
          }).catch((err) => {
              swal("Something Wrong!", `${err}`, "error");
              // setLoading(false)
          }) 
          return response;
        } catch (error) {
          swal("Something Wrong!", `${error}`, "error");
        }       
    }

    const loadPlatform = async () => {
      // setLoading(true)
      try {
        const response = await Axios.get(`${API}/admin/platform/gets`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setDataPlatform(res.data.results)              
            // setLoading(false)
        }).catch((err) => {
            swal("Something Wrong!", `${err}`, "error");
            // setLoading(false)
        }) 
        return response;
      } catch (error) {
        swal("Something Wrong!", `${error}`, "error");
      }       
    }

    loadCompany()
    loadPlatform()


  }, []);


  const renderCompany = () => {
    if(baseprice) {
      return (
        <>
        {baseprice.map((data, index) => (
          <TableRow
            className={classes.tableRow}
            hover
            key={data.id}
          >
            <TableCell >
            { dataBaseprice.page > 1 ? 
                  <>
                    { index + 1 + (dataBaseprice.perPage  * (dataBaseprice.page - 1) ) }
                  </>
                  : 
                  <>
                  {index  + 1}
                  </>
              }
            </TableCell>
            <TableCell>
              <div className={classes.nameContainer}>
                
                <Avatar
                  className={classes.avatar}
                  src={data.platform_child.platform.icon_url}
                >                          
                </Avatar>
                
                <Typography variant="body1">{data.platform_child.platform.platform_name}</Typography>
              </div>
            </TableCell>
            
            <TableCell>{data.platform_child.name}</TableCell>
            <TableCell>{data.level}</TableCell>
            <TableCell>{data.ppc_price.toLocaleString()}</TableCell>
            <TableCell>{data.post_price.toLocaleString()}</TableCell>
            
            <TableCell >
              <Button 
                style={{ marginRight: 10 }} 
                variant='contained' 
                color='primary' 
                onClick={() => {
                  setEditBaseprice({...editBaseprice,
                    id: data.id,
                    ppc_price: data.ppc_price,
                    post_price: data.post_price,
                    dialog: true
                  })
                }}
              >Edit</Button>
              <Button 
                onClick={() => {
                  let state = {
                    id: data.id,
                  }
                  deleteDataBaseprice(state)
                 }}
                style={{ marginRight: 10 }} 
                variant='contained' 
                color='default' 
              >Delete</Button>
              
            </TableCell>     
            
          </TableRow>
        ))}
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <div className={classes.rowTop}>
        <Grid container direction='row' justify='flex-start'>
          <Button 
            size='medium' 
            variant='contained' 
            color='primary' 
            onClick={handleClickRegister}
          >
            Create New Base Price
          </Button>
        </Grid>
      </div>

      
      {/* Start Modal Register */}
      <Dialog open={createBaseprice.dialog} onClose={handleCloseCreate} aria-labelledby="form-dialog-title">
        <Form onSubmit={onSubmitCreateBaseprice} validate >
          <DialogTitle id="form-dialog-title">Create New Baseprice</DialogTitle>
          <DialogContent>
            <FormControl variant="outlined"  fullWidth >
              <Select
                native
                margin="dense"
                style={{marginBottom: '15px'}}
                // value={state.age}
                onChange={(e) => handleChangePlatform(e.target.value)}
              >
                 <option aria-label="None">Select Platform </option>
                {dataPlatform && dataPlatform.map((data, index) => (
                  <option key={index} value={data.id}>{data.platform_name}</option>
                ))}
                                
              </Select>
            </FormControl>

            <FormControl variant="outlined"  fullWidth >              
              <Select
                native
                margin="dense"
                style={{marginBottom: '15px'}}
                onChange={handleChangeCreate('platform_child_id')}
                
              >
                <option aria-label="None">Select Platform Child</option>
                {dataPlatformChild && dataPlatformChild.map((data, index) => (
                  <option key={index} value={data.id}>{data.name}</option>
                ))}
                
              </Select>
            </FormControl>
            
            <TextField
              margin="dense"
              style={{marginBottom: '15px'}}
              id="outlined-multiline-static"
              variant="outlined" 
              label="Level Price (Range 1-10)"
              fullWidth
              onChange={handleChangeCreate('level')}
              required
            />
            <TextField
              margin="dense"
              style={{marginBottom: '15px'}}
              id="contact"
              label="Price per Click"
              type="number"
              variant="outlined"
              fullWidth
              onChange={handleChangeCreate('ppc_price')}
              required
            />
            <TextField
              margin="dense"
              style={{marginBottom: '15px'}}
              id="fee"
              label="Price per Post"
              type="number"
              variant="outlined"
              fullWidth
              onChange={handleChangeCreate('post_price')}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCreate} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Register */}

      {/* Start Modal Edit */}
      <Dialog open={editBaseprice.dialog} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
        <Form onSubmit={onSubmitEdit} validate >
          <DialogTitle id="form-dialog-title">Edit Base Price</DialogTitle>
          <DialogContent>
          <TextField
              style={{marginBottom: '20px'}}
              id="contact"
              label="Price per Click"
              type="number"
              variant="outlined"
              defaultValue={editBaseprice.ppc_price}
              fullWidth
              onChange={handleChangeEdit('ppc_price')}
              required
            />
            <TextField
              id="fee"
              label="Price per Post"
              type="number"
              variant="outlined"
              defaultValue={editBaseprice.post_price}
              fullWidth
              onChange={handleChangeEdit('post_price')}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Edit */}

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="5px">No</TableCell>
                    <TableCell>Platform</TableCell>
                    <TableCell>Platform Child</TableCell>
                    <TableCell>Level</TableCell>                  
                    <TableCell>Price per Click</TableCell>
                    <TableCell>Price per Post</TableCell>
                    <TableCell>Aksi</TableCell>                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderCompany()} 
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={dataBaseprice.total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={10}
          />
        </CardActions>
      </Card>

    </React.Fragment>
  );
};

BasepriceTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default BasepriceTable;
