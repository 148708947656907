import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
 } from '@material-ui/core';
 
 import TextField from '@material-ui/core/TextField'; 
 import Dialog from '@material-ui/core/Dialog';
 import DialogActions from '@material-ui/core/DialogActions';
 import DialogContent from '@material-ui/core/DialogContent';
 import DialogTitle from '@material-ui/core/DialogTitle';
 
import {Form} from 'react-bootstrap' 
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import NativeSelect from '@material-ui/core/NativeSelect';

import Axios from "axios";
import { API, UserToken } from '../../../../config'
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
  root: {},
  rowTop : {
    paddingBottom: '20px'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UserAdminTable = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();

  // initial state 
  const [user, setUser] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [registerUser, setRegisterUser] = useState({
    fullname: "",
    email: "",
    phone_number: "",
    password: "",
    company_id: "",
    dialog: false
  });


  const [editUser, setEditUser] = useState({
    fullname: "",
    email: "",
    phone_number: "",
    password: "",
    company_name: "",
    company_id: "",
    id: "",
    dialog: false
  });

  // function 
 
  useEffect(()=>{    
    const loadUser = async () => {
        try {
          const response = await Axios.get(`${API}/admin/user?page=1&rows=10`, {
            headers: {                    
                Authorization: `Bearer ${UserToken}`
            }
          })
          .then((res) => {
              setUser(res.data.results.data)
              setDataUser(res.data.results)      
          }).catch((err) => {
              swal("Something Wrong!", `${err}`, "error");
          }) 
          return response;
        } catch (error) {
          swal("Something Wrong!", `${error}`, "error");
        }       
    }
    
    const loadCompanyData = async () => {
      try {
        const response = await Axios.get(`${API}/admin/user/company/gets`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setDataCompany(res.data.results)      
        }).catch((err) => {
            swal("Something Wrong!", `${err}`, "error");
        }) 
        return response;
      } catch (error) {
        swal("Something Wrong!", `${error}`, "error");
      }       
    }

    loadUser()
    loadCompanyData()

  }, []);

  const loadUserPaginate = async (page) => {
    try {
      let page_data = page + 1
      let rows_data = 10

      const response = await  Axios.get(`${API}/admin/user?page=${page_data}&rows=${rows_data}`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setUser(res.data.results.data)
            setDataUser(res.data.results)
        }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
        })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }

  }

  // register handler
  const handleClickRegister = () => {
    setRegisterUser({...registerUser, dialog: true});
  };

  const handleCloseRegister = () => {
    setRegisterUser({...registerUser, dialog: false});
  };

  const handleChangeRegister = company => event => {
    setRegisterUser({...registerUser, [company]: event.target.value})
  }

  // edit handler
  const handleCloseEdit = () => {
    setEditUser({...editUser, dialog: false});
  };

  const handleChangeEdit = company => event => {
    setEditUser({...editUser, [company]: event.target.value})
  }

  const onSubmitEdit = async (e) => {
    e.preventDefault()
    // console.log(editUser, 'editUser')
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        fullname: editUser.fullname,
        email: editUser.email,
        company_id: editUser.company_id,
        phone_number: editUser.phone_number, 
        password: editUser.password, 
        id: editUser.id
      }
      const response = await Axios.put(`${API}/admin/user`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {
            Axios.get(`${API}/admin/user?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setUser(res.data.results.data)
                setDataUser(res.data.results)  
            }).catch((err) => {
                swal("Something Wrong!", `${err}`, "error");
            })
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              handleCloseEdit()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  const onSubmitRegister = async (e) => {
    e.preventDefault()
    // console.log(registerUser, 'register User')
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        fullname: registerUser.fullname,
        email: registerUser.email,
        phone_number: registerUser.phone_number,
        password: registerUser.password,
        company_id: registerUser.company_id,
      }
      const response = await Axios.post(`${API}/admin/user`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {
            Axios.get(`${API}/admin/user?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setUser(res.data.results.data)
                setDataUser(res.data.results)   
            }).catch((err) => {
                swal("Something Wrong!", `${err}`, "error");
            })
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              handleCloseRegister()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  const deleteDataUser = (state) => {

    swal({
      title: "Are you sure?",
      text: `Once deleted, you will not be able to recover the data!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        swal("Something Wrong!", "You are not Authorized to this account!", "error");
      } else {
        swal.close()  
        
      }
    });
  }

  const handlePageChange = (event, page) => {
    setPage(page);
    loadUserPaginate(page)
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  
  
  const renderCompany = () => {
    if(user) {
      return (
        <>
        {user.map((data, index) => (
          <TableRow
            className={classes.tableRow}
            hover
            key={data.id}
          >
            <TableCell >
            { dataUser.page > 1 ? 
                  <>
                    { index + 1 + (dataUser.perPage  * (dataUser.page - 1) ) }
                  </>
                  : 
                  <>
                  {index  + 1}
                  </>
              }
            </TableCell>
            
            <TableCell>{data.fullname}</TableCell>
            <TableCell>{data.email}</TableCell>
            <TableCell>{data.phone_number === null ? '-' : data.phone_number}</TableCell>
            <TableCell>{data.company === null ? 'SUPER ADMIN' : data.company.company}</TableCell>
            <TableCell>{data.status }</TableCell>
            <TableCell >
              <Button 
                style={{ marginRight: 10 }} 
                variant='contained' 
                color='primary' 
                onClick={() => {
                  setEditUser({...editUser,
                    fullname: data.fullname,
                    email: data.email,
                    phone_number: data.phone_number === null ? '-' : data.phone_number,
                    company_name: data.company === null ? "Superadmin" : data.company.company,
                    company_id: data.company === null ? "" : data.company.id,
                    id: data.id,
                    dialog: true
                  })
                }}
              >Edit</Button>
              <Button 
                onClick={() => {
                  let state = {
                    id: data.id,
                    company: data.company
                  }
                  deleteDataUser(state)
                 }}
                style={{ marginRight: 10 }} 
                variant='contained' 
                color='default' 
              >Delete</Button>
              
            </TableCell>     
            
          </TableRow>
        ))}
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <div className={classes.rowTop}>
        <Grid container direction='row' justify='flex-start'>
          <Button 
            size='medium' 
            variant='contained' 
            color='primary' 
            onClick={handleClickRegister}
          >
            Add User Admin
          </Button>
        </Grid>
      </div>

      
      {/* Start Modal Register */}
      <Dialog open={registerUser.dialog} onClose={handleCloseRegister} aria-labelledby="form-dialog-title">
        <Form onSubmit={onSubmitRegister} validate >
          <DialogTitle id="form-dialog-title">Add User Admin</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Full Name"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeRegister('fullname')}
              required
            />
            <TextField
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              variant="outlined"
              fullWidth
              onChange={handleChangeRegister('email')}
              required
            />
           <TextField
              margin="dense"
              id="contact"
              label="Please type Password"
              type="password"
              variant="outlined"
              fullWidth
              onChange={handleChangeRegister('password')}
              required
            />
            <TextField
              margin="dense" 
              id="fee"
              label="Phone Number"
              type="number"
              variant="outlined"
              fullWidth
              onChange={handleChangeRegister('phone_number')}
              required
            />
            <FormControl variant="outlined"  fullWidth className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Choose Company</InputLabel>
              <Select
                native
                // value={state.age}
                onChange={handleChangeRegister('company_id')}
                inputProps={{
                  name: 'Choose Company',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option aria-label="None"/>
                {dataCompany && dataCompany.map((data, index) => (
                  <option key={index} value={data.id}>{data.company}</option>
                ))}
                
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRegister} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Register */}

      {/* Start Modal Edit */}
      <Dialog open={editUser.dialog} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
        <Form onSubmit={onSubmitEdit} validate >
          <DialogTitle id="form-dialog-title">Edit Company</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              label="Full Name"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeEdit('fullname')}
              defaultValue={editUser.fullname}
              required
            />
            <TextField
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              variant="outlined"
              fullWidth
              onChange={handleChangeEdit('email')}
              defaultValue={editUser.email}
              required
            />
           <TextField
              margin="dense"
              id="contact"
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              onChange={handleChangeEdit('password')}
              defaultValue={editUser.password}
              required
            />
            <TextField
              margin="dense"
              id="fee"
              label="Phone Number"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeEdit('phone_number')}
              defaultValue={editUser.phone_number}
              required
            />
            <FormControl variant="outlined"  fullWidth className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Choose Company</InputLabel>
              <Select
                native
                // value={state.age}
                onChange={handleChangeEdit('company_id')}
                inputProps={{
                  name: 'Choose Company',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option>{editUser.company_name}</option>
                {dataCompany && dataCompany.map((data, index) => (
                  <option key={index} value={data.id}>{data.company}</option>
                ))}
                
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Edit */}

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="5px">No</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Status</TableCell>                  
                    <TableCell>Aksi</TableCell>                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderCompany()} 
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={dataUser.total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={10}
          />
        </CardActions>
      </Card>

    </React.Fragment>
  );
};

UserAdminTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UserAdminTable;
