import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TablePagination
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import {Form} from 'react-bootstrap'
import {Alert} from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { 
  updateInterest,
  removeInterest
} from './../../../../redux';
import { API, UserToken } from '../../../../config'
import Axios from 'axios'


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const InterestTable = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch()

  const [dataInterest, setDataInterest] = useState([])
  const [totalPage, setTotalPage] = useState([])

  // console.log(totalPage) 

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  
  const handlePageChange = (event, page) => {
    setPage(page);
    loadInfluencerPaginate(page)
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const loadInfluencerPaginate = async (page) => {
    // console.log(page, 'iki apa sek bro')
    let page_data = page + 1
    let rows_data = 10

    const response = await  Axios.get(`${API}/admin/interest?page=${page_data}&rows=${rows_data}`, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          setDataInterest(res.data.results.data)
          setTotalPage(res.data.results)
      }).catch((err) => {
          console.log(err)
      })
    return response;

  }


  useEffect(()=>{
    const loadInterest = () => {
      Axios.get(`${API}/admin/interest?page=1&rows=10`, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          setDataInterest(res.data.results.data)
          setTotalPage(res.data.results)
      }).catch((err) => {
          console.log(err)
      })
    }
    loadInterest()

  }, []);


  const [interestEdit, setInterestEdit] = useState({
    dialog : false,
    idEdit: '',
    name : '',
    image : [],
    error: false
  });

  const [interestDelete, setInterestDelete] = useState({
    dialog : false,
    idDelete: '',
  });

  const handleChange = name => event => {
    setInterestEdit({...interestEdit, [name]: event.target.value})
  }

  const handleClose = () => {
    setInterestEdit({...interestEdit, dialog : false });
  };

  const handleCloseDelete = () => {
    setInterestDelete({...interestDelete, dialog : false });
  };

  const submitUpdate = () => {    
    if(interestEdit.image.length === 0){
      setInterestEdit({...interestEdit, error : true });
    } else {
      dispatch(updateInterest(interestEdit))
      setInterestEdit({...interestEdit, dialog : false });
    }
  } 

  const errorMessage = () => {
    if(interestEdit.error){
      return (
        <Alert variant="danger" >
          Please upload photo Fisrt!
        </Alert>
      )
    } 
  }

  const renderDataInterest = () => {
    if(dataInterest) {
      return (
        <TableBody>
                {dataInterest.map((interest, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={index}                   
                  >
                    
                    <TableCell width='40px' >
                      <Typography variant="body1">
                      { totalPage.page > 1 ? 
                          <>
                            { index + 1 + (totalPage.perPage  * (totalPage.page - 1) ) }
                          </>
                          : 
                          <>
                          {index  + 1}
                          </>
                      }
                      
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={interest.iconUrl}
                        >
                        </Avatar>
                        <Typography variant="body1">{interest.interest_name}</Typography>
                      </div>
                    </TableCell>

                    <TableCell >
                      <Button 
                        style={{ marginRight: 10 }} 
                        variant='contained' 
                        color='primary' 
                        onClick={() => setInterestEdit({
                          ...interestEdit, 
                          dialog : true,
                          name: interest.interest_name,
                          idEdit: interest.id,
                        })}
                      > Edit
                      </Button>
                      {/* <Button 
                        variant='contained' 
                        color='secondary'
                        onClick={() => setInterestDelete({
                          ...interestDelete, 
                          dialog : true,                         
                          idDelete: interest.id,
                        })}
                      >Delete
                      </Button> */}
                    </TableCell>
                   
                  </TableRow>
                ))}
              </TableBody> 
      )
    }
  }

  return (
  <>

  <Dialog open={interestEdit.dialog} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogContent>
      <DialogContentText>
        <Box pt={2} fontSize={25}>Update Interest</Box>
      </DialogContentText>
      <TextField
        autoFocus
        onChange={handleChange('name')}            
        id="name"
        label="Interest Name"
        type="text"
        defaultValue={interestEdit.name}
        fullWidth
      />          
    <Box pt={2}>
    <Form.Group>
      <Form.File
        onChange={(e) => setInterestEdit({
          ...interestEdit,
          image: e.target.files[0],
          error: false
        })}
        id="image" 
        name="image" 
        label="Interest Image" 
      />
    </Form.Group>
    {errorMessage()}
    </Box>

    </DialogContent>
    <DialogActions>
      <Button variant='contained' onClick={handleClose} color="danger">
        Cancel
      </Button>
      <Button 
        variant='contained' 
        onClick={submitUpdate} 
        color="primary">
        Submit
      </Button>
    </DialogActions>
  </Dialog>


  <Dialog open={interestDelete.dialog} onClose={handleCloseDelete} aria-labelledby="form-dialog-title">
    <DialogContent>
      <DialogContentText>
        <Box pt={2} fontSize={25}>Warning</Box>
      </DialogContentText>
              
    <Box pt={2}>
    Are you sure want to delete this data?
    </Box>

    </DialogContent>
    <DialogActions>
      <Button variant='contained' onClick={handleCloseDelete} color="danger">
        Cancel
      </Button>
      <Button 
        variant='contained' 
        onClick={() => {
          dispatch(removeInterest(interestDelete.idDelete))
          setInterestDelete({...interestDelete, dialog: false})
        } } 
        color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>


    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>                  
                  <TableCell>No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                  
                </TableRow>
              </TableHead>
              {renderDataInterest()}
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
      <TablePagination
          component="div"
          count={totalPage.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={10}
        />
      </CardActions>
    </Card>

  </>
  ); 
}; 

InterestTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default InterestTable;
