import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  TextField,
  Box,
  Container,
  Typography,
  Button
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import Axios from "axios";
import { API, UserToken } from '../../config'
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import swal from 'sweetalert';


const useStyles = makeStyles(() => ({
  rootComponent: {
    paddingTop: '50px',
    paddingBottom: '50px',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  formStyle: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: '80px',
    },
  },
  textError: {
    textTransform: 'capitalize'
  },
  textField: {
    width: '100%',
  },
  margin: {
    marginTop: '20px',
  },
}));

const AddUser = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    fullname: '',
    email : '',
    password: '',
    repassword: '',
    phone_number : '',
    showPassword: false,
    showRePassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowRePassword = () => {
    setValues({ ...values, showRePassword: !values.showRePassword });
  };

  const handleMouseDownRePassword = (event) => {
    event.preventDefault();
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  
  const onSubmitRegister = async (event) => {
    event.preventDefault();
    let data = { 
      fullname : values.fullname,
      email : values.email,
      password : values.password,
      phone_number : values.phone_number,
      status : "ACTIVE"
    }

    if (values.password !== values.repassword) {
      swal("Something Wrong!", "Password doesn't match!", "error");
    } else {
      // console.log(data, 'iki apa sek bro')
      const response = await  Axios.post(`${API}/admin/influencer`, data, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            swal("Success", `${res.data.message}`, "success");
            window.location.href = "/users"
            setTimeout(() => {
              window.location.reload()
            }, 1500);

        }).catch((err) => {
            swal("Something Wrong!", `${err.response.data.message}`, "error");            
        })
      return response;
    }
  }


  return (
  <>
    
    <div className={classes.rootComponent} >

      <Box pb={4} >
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/dashboard" >
           Dashboard
          </Link>
          <Link color="inherit" to="/users" >
            Influencer
          </Link>
          <Typography color="textPrimary">Register Influencer</Typography>
        </Breadcrumbs>
      </Box>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      > 
      <Container maxWidth='md' >
      <form className={classes.formStyle} validate onSubmit={onSubmitRegister} >
        <CardHeader
          subheader="Please input data in the form bellow"
          title="Register Influencer"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Full Name"
            name="fullname"
            onChange={handleChange}
            type="text"
            value={values.fullname}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            required
            label="Email Address"
            name="email"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="text"
            value={values.email}
            variant="outlined"
          />
          {/* <TextField
            fullWidth
            required
            label="Password"
            name="password"
            style={{ marginTop: '1rem' }}
            onChange={handleChange('password')}
            type="password"
            // value={values.password}
            variant="outlined"
          /> */}

        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            name="password"
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>

        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Retype Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showRePassword ? 'text' : 'password'}
            value={values.repassword}
            name="repassword"
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle repassword visibility"
                  onClick={handleClickShowRePassword}
                  onMouseDown={handleMouseDownRePassword}
                  edge="end"
                >
                  {values.showRePassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>

          <TextField
            fullWidth
            required
            label="Phone Number"
            name="phone_number"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="number"
            value={values.phone_number}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Grid container direction='row' justify='center' >
          <Button
            color="secondary"
            variant="contained"
            type="submit"

          >
            Submit
          </Button>
        </Grid>
      </form>
      </Container>
    </Card>

    </div>

    
  </>
  );
};

AddUser.propTypes = {
  className: PropTypes.string
};

export default AddUser;
