import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Paper,
  Box,
  Divider,
  Button
} from '@material-ui/core';

import Axios from "axios";
import { API, UserToken } from '../../../../config'
import TextField from '@material-ui/core/TextField'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Form} from 'react-bootstrap' 
import swal from 'sweetalert';

import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: '20px'
  },
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '70px',
    height: '70px',
    borderRadius: '15px'
  },
  heightPaper: {
    height: 'fit-content'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  }
}));

const ProductCard = props => {

  const classes = useStyles();

  const [dataClient, setDataClient] = useState([]);


  const [createClient, setCreateClient] = useState({
    name: "",
    domain: "",
    ip: "",
    email: "",
    api_response: "",
    client_type: "",
    dialog: false
  });

  const [editClient, setEditClient] = useState({
    name: "",
    domain: "",
    ip: "",
    email: "",
    api_response: "",
    client_type: "",
    id: "",
    status: "",
    dialog: false
  });

  // create
  const handleClickRegister = () => {
    setCreateClient({...createClient, dialog: true});
  };

  const handleCloseCreate = () => {
    setCreateClient({...createClient, dialog: false});
  };

  const handleChangeCreate = name => event => {
    setCreateClient({...createClient, [name]: event.target.value})
  }

  // edit
 
  const handleCloseEdit = () => {
    setEditClient({...editClient, dialog: false});
  };

  const handleChangeEdit = name => event => {
    setEditClient({...editClient, [name]: event.target.value})
  }
    

  useEffect(()=>{    
    const loadClient = () => {
        // setLoading(true)
            Axios.get(`${API}/admin/client?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {   
                setDataClient(res.data.results.data)   
            }).catch((err) => {
                swal("Something Wrong!", `${err}`, "error");
            })        
    }
    loadClient()
  }, []);

 
  const onSubmitCreateClient = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        name: createClient.name,
        domain: createClient.domain,
        ip: createClient.ip,
        email: createClient.email,
        api_response: createClient.api_response,
        client_type: createClient.client_type
      }
      const response = await Axios.post(`${API}/admin/client`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {
            Axios.get(`${API}/admin/client?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {   
                setDataClient(res.data.results.data)   
            }).catch((err) => {
                swal("Something Wrong!", `${err}`, "error");
            })   
            swal("Success!", "Data success adedd!", "success");
            setTimeout(() => {
              swal.close()
              setCreateClient({...createClient, dialog: false})
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }


  const onSubmitEditClient = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        name: editClient.name,
        domain: editClient.domain,
        ip: editClient.ip,
        email: editClient.email,
        api_response: editClient.api_response,
        client_type: editClient.client_type,
        status: editClient.status,
        id: editClient.id,
      }
      const response = await Axios.put(`${API}/admin/client`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {
            Axios.get(`${API}/admin/client?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {   
                setDataClient(res.data.results.data)   
            }).catch((err) => {
                swal("Something Wrong!", `${err}`, "error");
            })   
            swal("Success!", "Data success adedd!", "success");
            setTimeout(() => {
              swal.close()
              setEditClient({...editClient, dialog: false})
              // window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  return (
    <>

  <div>
      <div className={classes.row}>        
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickRegister}
        >
          Add New Client
        </Button>
      </div>    
    </div>
    <Grid container spacing={2}>
    { dataClient && dataClient.map((data, index) => {
      return (
      <Grid item lg={4} key={index}>

        <Paper elevation={4} className={classes.heightPaper} >
          <Box p={2}>
            <Grid container direction='column' alignItems="center" >
              <Box pb={2} pt={2} fontSize={25} fontWeight="bold">
                {data.name}
              </Box>
              <Divider/>

              <Grid container>
                <Grid item lg={3}>Domain</Grid>
                <Grid item lg={9}>{data.domain}</Grid>
              </Grid>

              <Grid container>
                <Grid item lg={3}>Email</Grid>
                <Grid item lg={9}>{data.email}</Grid>
              </Grid>
              
              <Grid container>
                <Grid item lg={3}>Key</Grid>
                <Grid item lg={9}>{data.key}</Grid>
              </Grid>

              <Grid container>
                <Grid item lg={3}>Ip</Grid>
                <Grid item lg={9}>{data.ip}</Grid>
              </Grid>

              <Grid container>
                <Grid item lg={3}>Api Response</Grid>
                <Grid item lg={9}>{data.api_response}</Grid>
              </Grid>

              <Grid container>
                <Grid item lg={3}>Status</Grid>
                <Grid item lg={9}>{data.status}</Grid>
              </Grid>

              
              <Grid container>
                <Grid item lg={3}>Client Type</Grid>
                <Grid item lg={9}>{data.client_type}</Grid>
              </Grid>

              <Grid container>
                <Grid item lg={3}>Fee</Grid>
                <Grid item lg={9}>{data.fee === null ? '-' : data.fee}</Grid>
              </Grid>

              <Box pt={4} pb={2} >
                <Button 
                  variant="contained" color="secondary" size="small"
                  onClick={() => setEditClient({
                    ...editClient, 
                        name: data.name,
                        domain: data.domain,
                        ip: data.ip,
                        email: data.email,
                        api_response: data.api_response,
                        client_type: data.client_type,
                        id: data.id,
                        status: data.status,
                        dialog: true
                  })}
                  
                  >Edit Data</Button>
              </Box>

            </Grid>
          </Box>          
        </Paper>  
      </Grid>  
      )
    })}
    </Grid>

    {/* Start Modal Register */}
    <Dialog open={createClient.dialog} onClose={handleCloseCreate} aria-labelledby="form-dialog-title">
        <Form onSubmit={onSubmitCreateClient} validate >
          <DialogTitle id="form-dialog-title">Create New Client</DialogTitle>
          <DialogContent>
            <TextField
              id="outlined-multiline-static"
              variant="outlined" 
              label="Client Name"
              fullWidth
              type='text'
              onChange={handleChangeCreate('name')}
              required
            />
            <TextField
              margin="dense"
              id="contact"
              label="Domain"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeCreate('domain')}
              required
            />
            <TextField
              margin="dense"
              id="fee"
              label="email"
              type="email"
              variant="outlined"
              fullWidth
              onChange={handleChangeCreate('email')}
              required
            />
            <TextField
              margin="dense"
              id="contact"
              label="IP"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeCreate('ip')}
              required
            />
            <TextField
              margin="dense"
              id="contact"
              label="Api Response"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeCreate('api_response')}
              required
            />
            <TextField
              margin="dense"
              id="contact"
              label="Client Type"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeCreate('client_type')}
              required
            />
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCreate} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Register */}


      {/* Start Modal edit */}
    <Dialog open={editClient.dialog} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
        <Form onSubmit={onSubmitEditClient} validate >
          <DialogTitle id="form-dialog-title">Edit Client</DialogTitle>
          <DialogContent>
            <TextField
              id="outlined-multiline-static"
              variant="outlined" 
              label="Client Name"
              fullWidth
              type='text'
              onChange={handleChangeEdit('name')}
              defaultValue={editClient.name}
              required
            />
            <TextField
              margin="dense"
              id="contact"
              label="Domain"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeEdit('domain')}
              defaultValue={editClient.domain}
              required
            />
            <TextField
              margin="dense"
              id="fee"
              label="email"
              type="email"
              variant="outlined"
              fullWidth
              onChange={handleChangeEdit('email')}
              defaultValue={editClient.email}
              required
            />
            <TextField
              margin="dense"
              id="contact"
              label="IP"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeEdit('ip')}
              defaultValue={editClient.ip}
              required
            />
            <TextField
              margin="dense"
              id="contact"
              label="Api Response"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeEdit('api_response')}
              defaultValue={editClient.api_response}
              required
            />
            <TextField
              margin="dense"
              id="contact"
              label="Client Type"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChangeEdit('client_type')}
              defaultValue={editClient.client_type}
              required
            />
            <FormControl variant="outlined"  fullWidth className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
              <Select
                native
                // value={state.age}
                onChange={handleChangeEdit('status')}
                inputProps={{
                  name: 'Status',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option aria-label="None"/>               
                <option value="AKTIVE">AKTIVE</option>
                <option value="BLOCK">BLOCK</option>                                
              </Select>
            </FormControl>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal edit */}
  </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string
};

export default ProductCard;
