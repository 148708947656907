import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Divider,
  Box,
  Container,
  Typography,
  Button
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import Axios from "axios";
import { API, UserToken } from '../../config'

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import swal from 'sweetalert';


const useStyles = makeStyles(() => ({
  rootComponent: {
    paddingTop: '50px',
    paddingBottom: '50px',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  formStyle: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: '80px',
    },
  },
  textError: {
    textTransform: 'capitalize'
  },
  formControl: {
    marginBottom: '20px'
  }
}));

const MappingUser = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [dataUser, setDataUser] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);

  const [mappingUser, setMappingUser] = useState({
    user_id: '',
    company_id : ''
  });

  const onChangeMap = user_id => event => {
    setMappingUser({...mappingUser, [user_id]: event.target.value})
  }
  
  const onSubmitMappingUser = async (event) => {
    event.preventDefault();
    
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        }, 
      };

      let body = {
        user_id: mappingUser.user_id,
        company_id: mappingUser.company_id,
      }
      const response = await Axios.post(`${API}/admin/company/mapuser`, body, Headers)
      .then((res) => {   
          swal("Success!", `${res.data.message}`, "success");
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }

  }

  useEffect(()=>{    
    const loadUser = async () => {
        try {
          const response = await Axios.get(`${API}/admin/influencer/gets`, {
            headers: {                    
                Authorization: `Bearer ${UserToken}`
            }
          })
          .then((res) => {
              setDataUser(res.data.results)      
          }).catch((err) => {
              swal("Something Wrong!", `${err}`, "error");
          }) 
          return response;
        } catch (error) {
          swal("Something Wrong!", `${error}`, "error");
        }       
    }
    
    const loadCompanyData = async () => {
      try {
        const response = await Axios.get(`${API}/admin/user/company/gets`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setDataCompany(res.data.results)      
        }).catch((err) => {
            swal("Something Wrong!", `${err}`, "error");
        }) 
        return response;
      } catch (error) {
        swal("Something Wrong!", `${error}`, "error");
      }       
    }

    loadUser()
    loadCompanyData()

  }, []);


  return (
  <>
    
    <div className={classes.rootComponent} >
      <Box pb={4} >
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/dashboard" >
           Dashboard
          </Link>          
          <Typography color="textPrimary">Mapping User</Typography>
        </Breadcrumbs>
      </Box>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      > 
      <Container maxWidth='md' >
      <form className={classes.formStyle} validate onSubmit={onSubmitMappingUser} >
       
        <CardContent>
            <FormControl variant="outlined"  fullWidth className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Choose User</InputLabel>
              <Select
                native
                // value={state.age}
                onChange={onChangeMap('user_id')}
                inputProps={{
                  name: 'Choose Company',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option aria-label="None"/>
                {dataUser && dataUser.map((data, index) => (
                  <option key={index} value={data.id}>{data.fullname}</option>
                ))}
                
              </Select>
            </FormControl>


            <FormControl variant="outlined"  fullWidth className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Choose Company</InputLabel>
              <Select
                native
                // value={state.age}
                onChange={onChangeMap('company_id')}
                inputProps={{
                  name: 'Choose Company',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option aria-label="None"/>
                {dataCompany && dataCompany.map((data, index) => (
                  <option key={index} value={data.id}>{data.company}</option>
                ))}
                
              </Select>
            </FormControl>
         
          
        </CardContent>
        <Divider />
        <Grid container direction='row' justify='center' >
          <Button
            color="secondary"
            variant="contained"
            type="submit"

          >
            Submit
          </Button>
        </Grid>
      </form>
      </Container>
    </Card>

    </div>

    
  </>
  );
};

MappingUser.propTypes = {
  className: PropTypes.string
};

export default MappingUser;
