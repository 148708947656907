import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Link
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Axios from "axios";
import { API, UserToken } from '../../../../config'
import img from '../../../../assets/img/avatars.png'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestInfluencer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [user, setUser] = useState([]);

  useEffect(()=>{    
    const loadInfluencer = () => {
        // setLoading(true)
            Axios.get(`${API}/admin/influencer?page=1&rows=5`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setUser(res.data.results.data)
            }).catch((err) => {
                console.log(err)
            })        
    }
    loadInfluencer()
  }, []);
   
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader        
        title="Latest Influencers"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {user.map((user, i) => (
            <ListItem
              divider={i < user.length - 1}
              key={user.id}
            >
              <ListItemAvatar>
              {user.profile === null ?                 
                <img
                  alt="User"
                  className={classes.image}
                  src={img}
                />
                : 
                <img
                  alt="User"
                  className={classes.image}
                  src={user.profile.imageUrl === null ? img : user.profile.imageUrl}
                />
                }
                
              </ListItemAvatar>

              <ListItemText
                primary={user.fullname}
                secondary={`Created at ${moment(user.created_at).format("LL")}`}
              />

              <IconButton
                edge="end"
                size="small"
              >
                <MoreVertIcon />
              </IconButton>

            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Link href="/users">
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          View all <ArrowRightIcon />
        </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

LatestInfluencer.propTyps = {
  className: PropTypes.string
};

export default LatestInfluencer;
