import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TablePagination,
  Box
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Axios from "axios";
import { API, UserToken } from '../../../../config'
// import { useDispatch } from 'react-redux';
import img from './../../../../assets/img/avatars.png'


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const VerifyInfluencerTable = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();

  const [user, setUser] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  // const [loading, setLoading] = useState(false)
  // console.log(user, 'data user')
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  
  const handlePageChange = (event, page) => {
    setPage(page);
    loadInfluencerPaginate(page)
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  // const dispatch = useDispatch()
  
  const loadInfluencerPaginate = async (page) => {
    // console.log(page, 'iki apa sek bro')
    let page_data = page + 1
    let rows_data = 10

    const response = await  Axios.get(`${API}/admin/influencer/verify_account?page=${page_data}&rows=${rows_data}`, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          setUser(res.data.results.data)
          setDataUser(res.data.results)    
      }).catch((err) => {
          console.log(err)
      })
    return response;

  }

 
  useEffect(()=>{    
    const loadInfluencer = () => {
        // setLoading(true)
            Axios.get(`${API}/admin/influencer/verify_account?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setUser(res.data.results.data)
                setDataUser(res.data.results)              
                // setLoading(false)
            }).catch((err) => {
                console.log(err)
                // setLoading(false)
            })        
    }
    loadInfluencer()
  }, []);

  const [verifyAccount, setVerifyAccount] = useState({
    dialog : false,
    id: '',
  });

  const [alertError, setAlertError] = useState(false)
  const [alertSuccess, setAlertSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const onClickVerify = async (id) => {

    let data = { 
      id : id,
      verified : "true"
    }

    const verify = await  Axios.put(`${API}/admin/influencer/platform/update`, data, {
      headers: {                    
          Authorization: `Bearer ${UserToken}`
      }
    })
    .then((res) => {
      setAlertSuccess(true)
      setSuccessMessage(res.data.message)
      window.location.reload()
    }).catch((err) => {
      setAlertError(true)
      setErrorMessage(err.response.data.message[0].message)
    })

    return verify;
  }
 
  const handleCloseDelete = () => {
    setVerifyAccount({...verifyAccount, dialog : false });
  };

  
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertError(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertSuccess(false);
  };

  const renderInfluencer = () => {
    if(user.length !== 0) {
      return (
        <>
        {user.map((data, index) => (
          <TableRow
            className={classes.tableRow}
            hover
            key={data.id}
          >
            <TableCell >
            { dataUser.page > 1 ? 
                  <>
                    { index + 1 + (dataUser.perPage  * (dataUser.page - 1) ) }
                  </>
                  : 
                  <>
                  {index  + 1}
                  </>
              }
            </TableCell>
            <TableCell>
              <div className={classes.nameContainer}>
                <Avatar
                  className={classes.avatar}
                  src={img}
                >                          
                </Avatar>
              {/* {data.profile === null ?                 
                
                : 
                <Avatar
                  className={classes.avatar}
                  src={data.profile.imageUrl}
                >                          
                </Avatar>
                } */}
                
                <Typography variant="body1">
                  {data.influencer === null ? '' : data.influencer.fullname}
                </Typography>
              </div>
            </TableCell>
            <TableCell>{data.influencer === null ? '' : data.influencer.email}</TableCell>
            <TableCell>{
              data.platform_id === 1 ? 'Instagram' : 
              data.platform_id === 2 ? 'Facebook' : 
              data.platform_id === 3 ? 'YOutube' : 'Twitter'
            
            }</TableCell>
            <TableCell>{data.username}</TableCell>
            <TableCell>{data.followers.toLocaleString()}</TableCell>
            <TableCell>
              <Button 
                variant='contained' 
                color='secondary'
                onClick={() => setVerifyAccount({...verifyAccount, dialog: true, id : data.id})
               } 
              >
              Verify User</Button>
            </TableCell>
            
            
          </TableRow>
        ))}
        </>
      )
    } else {
      return (
        ''
      )
    }
  }

  return (
  <>
    <div className={classes.root}>
      <Snackbar 
        open={alertError} 
        autoHideDuration={2000} 
        onClose={handleCloseError}
        anchorOrigin={{ vertical : 'top', horizontal : 'right' }}
      >
        <Alert onClose={handleCloseError} severity="error">
          <Box className={classes.textError} >{errorMessage}</Box>
        </Alert>
      </Snackbar>

      <Snackbar 
        open={alertSuccess} 
        autoHideDuration={2000} 
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical : 'top', horizontal : 'right' }}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          <Box className={classes.textError} >{successMessage}</Box>
        </Alert>
      </Snackbar>

    </div>

    <Dialog open={verifyAccount.dialog} onClose={handleCloseDelete} aria-labelledby="form-dialog-title">
      <DialogContent>
        <DialogContentText>
          <Box pt={2} fontSize={25}>Warning</Box>
        </DialogContentText>
                
      <Box pt={2}>
      Are you sure want to Verify this User? 
      </Box>

      </DialogContent>
      <DialogActions style={{paddingBottom: '20px', paddingRight: '25px', paddingTop: '20px'}}>
        <Button size='small' variant='contained' onClick={handleCloseDelete} color="danger">
          Cancel
        </Button>
        <Button 
          variant='contained' 
          size='small'
          onClick={() => {
            let id = verifyAccount.id
            onClickVerify(id)
            setVerifyAccount({...verifyAccount, dialog: false})
          } } 
          color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>


    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="5px">No</TableCell>
                  <TableCell>Influencer Name</TableCell>
                  <TableCell>Influencer Email</TableCell>
                  <TableCell>Platform</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Followers</TableCell>                  
                  <TableCell>ACtion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderInfluencer()}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={dataUser.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={10}
        />
      </CardActions>
    </Card>
  </>
  );

};

VerifyInfluencerTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default VerifyInfluencerTable;
