import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
 } from '@material-ui/core';
import {Form} from 'react-bootstrap' 

import TextField from '@material-ui/core/TextField'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Axios from "axios";
import { API, UserToken } from '../../../../config'
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
  root: {},
  rowTop : {
    paddingBottom: '20px'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const BasepriceTable = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();

  // initial state 
  const [system, setSystem] = useState([]);
  const [dataSystem, setDataSystem] = useState([]);

  
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  
  const [editSystem, setEditSystem] = useState({
    id: "",
    value: "",
    dialog: false
  });
 
  

  // function 

  // edit handler
  const handleCloseEdit = () => {
    setEditSystem({...editSystem, dialog: false});
  };

  const handleChangeEdit = ppc_price => event => {
    setEditSystem({...editSystem, [ppc_price]: event.target.value})
  }

  const onSubmitEdit = async (e) => {
    e.preventDefault()
    // console.log(editSystem, 'editSystem')
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        id: editSystem.id,
        value: editSystem.value,
      }
      const response = await Axios.put(`${API}/admin/setting`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {
            Axios.get(`${API}/admin/setting?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setSystem(res.data.results.data)
                setDataSystem(res.data.results)  
            }).catch((err) => {
                swal("Something Wrong!", `${err}`, "error");
            })
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              handleCloseEdit()
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  const handlePageChange = (event, page) => {
    setPage(page);
    loadCompanyPaginate(page)
  };

  
  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  
  const loadCompanyPaginate = async (page) => {
    // console.log(page, 'iki apa sek bro')
    try {
      let page_data = page + 1
      let rows_data = 10

      const response = await  Axios.get(`${API}/admin/setting?page=${page_data}&rows=${rows_data}`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setSystem(res.data.results.data)
            setDataSystem(res.data.results)
        }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
        })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }

  }


    
  useEffect(()=>{    
    const loadSetting = async () => {
        // setLoading(true)
        try {
          const response = await Axios.get(`${API}/admin/setting?page=1&rows=10`, {
            headers: {                    
                Authorization: `Bearer ${UserToken}`
            }
          })
          .then((res) => {
              // console.log(res.data, 'ressss')
              setSystem(res.data.results.data)
              setDataSystem(res.data.results)              
              // setLoading(false)
          }).catch((err) => {
              swal("Something Wrong!", `${err}`, "error");
              // setLoading(false)
          }) 
          return response;
        } catch (error) {
          swal("Something Wrong!", `${error}`, "error");
        }       
    }

    loadSetting()

  }, []);


  const renderCompany = () => {
    if(system) {
      return (
        <>
        {system.map((data, index) => (
          <TableRow
            className={classes.tableRow}
            hover
            key={data.id}
          >
            <TableCell >
            { dataSystem.page > 1 ? 
                  <>
                    { index + 1 + (dataSystem.perPage  * (dataSystem.page - 1) ) }
                  </>
                  : 
                  <>
                  {index  + 1}
                  </>
              }
            </TableCell>
            
            <TableCell>{data.key}</TableCell>
            <TableCell>{data.value}</TableCell>
            
            <TableCell >
              <Button 
                style={{ marginRight: 10 }} 
                variant='contained' 
                color='primary' 
                onClick={() => {
                  setEditSystem({...editSystem,
                    id: data.id,
                    value: data.value,
                    dialog: true
                  })
                }}
              >Edit</Button>
              
            </TableCell>     
            
          </TableRow>
        ))}
        </>
      )
    }
  }

  return (
    <React.Fragment>
      {/* <div className={classes.rowTop}>
        <Grid container direction='row' justify='flex-start'>
          <Button 
            size='medium' 
            variant='contained' 
            color='primary' 
            onClick={handleClickRegister}
          >
            Create New Base Price
          </Button>
        </Grid>
      </div> */}

      
      {/* Start Modal Edit */}
      <Dialog open={editSystem.dialog} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
        <Form onSubmit={onSubmitEdit} validate >
          <DialogTitle id="form-dialog-title">Edit System</DialogTitle>
          <DialogContent>
          <TextField
              margin="dense"
              id="contact"
              label="Value"
              type="number"
              variant="outlined"
              defaultValue={editSystem.value}
              fullWidth
              onChange={handleChangeEdit('value')}
              required
            />
           
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Edit */}

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="5px">No</TableCell>
                    <TableCell>Key</TableCell>
                    <TableCell>Value</TableCell>                               
                    <TableCell>Action</TableCell>                               
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderCompany()} 
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={dataSystem.total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={10}
          />
        </CardActions>
      </Card>

    </React.Fragment>
  );
};

BasepriceTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default BasepriceTable;
