import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Grid, 
  Box,
  Container,
  Paper,
  TextField
} from '@material-ui/core';
import Axios from "axios";
import { API, UserToken } from '../../../../config'
import img from '../../../../assets/img/avatars.png'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Form} from 'react-bootstrap' 
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Select from '@material-ui/core/Select';
import LaunchIcon from '@material-ui/icons/Launch';
import DoneIcon from '@material-ui/icons/Done';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';
import swal from 'sweetalert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DatePicker from 'react-date-picker';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  avatarImg: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textField: {
    width: '100%',
  },
}));

const UsersTable = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();

  const [user, setUser] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [dataPlatform, setDataPlatform] = useState([]);
  const [dataPlatformChild, setDataPlatformChild] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
 
  const [userDetail, setUserDetail] = useState({
    fullname: "",
    email: "",
    phone_number: "",
    birthdays: "",
    status: "",
    created_at: "",
    company: [],
    profile: {},
    pricebook: [],
    platforms: [],
    interest_tag: "",
    id: "",
    dialog: false
  });

  const [editProfile, setEditProfile] = useState({
    user_id: "",
    bio: "",
    place_bod: "",
    birthdays: "",
    gender: "",
    address: "",
    dialog: false
  });


  const [editBasicProfile, setEditBasicProfile] = useState({
    id: "",
    fullname: "",
    phone_number: "",
    password: "",
    showPassword: false,
    dialog: false
  });

  const [changeImage, setChangeImage] = useState({
    urlImage: "",
    image: "",    
    dialog: false
  });

  const [editPlatform, setEditPlatform] = useState({
    username: "",
    followers: "",
    verified: "true",
    id: "",
    dialog: false
  });

  const [editPricebook, setEditPricebook] = useState({
    ppc_submision_price: "",
    post_submision_price: "",
    platform_child: "",
    submision_status: "ACCEPTED",
    id: "",
    level: "",
    dialog: false
  });

  const [ addPlatform, setAddPlatform] = useState({
    user_id: "",
    platform_id: "",
    username: "",
    followers: "",
    verified: "true",
    dialog: false
  });

  const [ addPricebook, setAddPricebook] = useState({
    user_id: "",
    platforms: [],
    platform_child_id: "",
    level: "1",
    post_submision_price: "",
    submision_status: "ACCEPTED",
    dialog: false
  });

  const handleClickShowPassword = () => {
    setEditBasicProfile({ ...editBasicProfile, showPassword: !editBasicProfile.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeEditProfile = bio => event => {
    setEditProfile({...editProfile, [bio]: event.target.value})
  }

  const handleChangeEditBasicProfile = fullname => event => {
    setEditBasicProfile({...editBasicProfile, [fullname]: event.target.value})
  }

  const handleEditPlatform = username => event => {
    setEditPlatform({...editPlatform, [username]: event.target.value})
  }

  const handleEditPricebook = ppc_submision_price => event => {
    setEditPricebook({...editPricebook, [ppc_submision_price]: event.target.value})
  }
  
  const handlePageChange = (event, page) => {
    setPage(page);
    loadInfluencerPaginate(page)
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  
  const loadInfluencerPaginate = async (page) => {
    let page_data = page + 1
    let rows_data = 10

    const response = await  Axios.get(`${API}/admin/influencer?page=${page_data}&rows=${rows_data}`, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          setUser(res.data.results.data)
          setDataUser(res.data.results)
      }).catch((err) => {
          console.log(err)
      })
    return response;
  }

  const handleChangePlatform = async(data) => {
    try {      
      let Headers = {
        headers: {                    
          Authorization: `Bearer ${UserToken}`
        }
      }
     
      const response = await  Axios.get(`${API}/admin/platform/child/gets?platform_id=${data}`, Headers, null)
        .then((res) => {
            setDataPlatformChild(res.data.results)
        }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
        })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  };
 
  useEffect(()=>{    
    const loadInfluencer = () => {
        // setLoading(true)
        Axios.get(`${API}/admin/influencer?page=1&rows=10`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setUser(res.data.results.data)
            setDataUser(res.data.results)              
            // setLoading(false)
        }).catch((err) => {
            swal("Something Wrong!", `${err.response.data.message}`, "error");
            // setLoading(false)
        })        
    }
    
    const loadPlatform = () => {
      // setLoading(true)
      Axios.get(`${API}/admin/platform/gets`, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          setDataPlatform(res.data.results)              
          // setLoading(false)
      }).catch((err) => {
          swal("Something Wrong!", `${err.response.data.message}`, "error");
          // setLoading(false)
      })        
    }
    loadPlatform()
    loadInfluencer()

  }, []);

  const  onSubmitProfileEdit = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let formdata = new FormData();                
      formdata.append("user_id", userDetail.id);
      formdata.append("bio", editProfile.bio);
      formdata.append("place_bod", editProfile.place_bod);
      formdata.append("gender", editProfile.gender);
      formdata.append("address", editProfile.address);      
      formdata.append("birthdays", moment(editProfile.birthdays).format("YYYY-MM-DD"));      

      const response = await Axios.post(`${API}/admin/influencer/profile`, formdata, Headers)
      .then((res) => {   
          if(res.data.status === "success") {
            Axios.get(`${API}/admin/influencer?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setUser(res.data.results.data)
                setDataUser(res.data.results)       
            }).catch((err) => {
                swal("Something Wrong!", `${err.response.data.message}`, "error");
            })
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              setEditProfile({...editProfile, dialog: false})
              setUserDetail({...userDetail, dialog: false})
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }


  const  onSubmitBasicProfileEdit = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };
      let body = {
        fullname: editBasicProfile.fullname,
        phone_number: editBasicProfile.phone_number,
        password: editBasicProfile.password,
        id: editBasicProfile.id,
      }      

      const response = await Axios.put(`${API}/admin/user`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {            
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              setEditBasicProfile({...editBasicProfile, dialog: false})
              setUserDetail({...userDetail, dialog: false})
              window.location.reload()
            }, 1500);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }    
  }

  const  onSubmitPlatformEdit = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };
      let body = {
        username: editPlatform.username,
        followers: editPlatform.followers,
        verified: editPlatform.verified,
        id: editPlatform.id,
      }      
      const response = await Axios.put(`${API}/admin/influencer/platform/update`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {            
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              setEditPlatform({...editPlatform, dialog: false})
              setUserDetail({...userDetail, dialog: false})
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  const  onSubmitPricebookEdit = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };
      let body = {
        // ppc_submision_price: editPricebook.ppc_submision_price,
        post_submision_price: editPricebook.post_submision_price,
        submision_status: editPricebook.submision_status,
        level: editPricebook.level,
        id: editPricebook.id
      }      

      const response = await Axios.put(`${API}/admin/influencer/pricebook`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {            
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              setEditPricebook({...editPricebook, dialog: false})
              setUserDetail({...userDetail, dialog: false})
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  const onSubmitPlatformAdd = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        username: addPlatform.username,
        followers: addPlatform.followers,
        verified: addPlatform.verified,
        user_id: addPlatform.user_id,
        platform_id: addPlatform.platform_id,
        liked: '10',
        commented: '10',
        is_connected: 'true'
      }      

      const response = await Axios.post(`${API}/admin/influencer/platform`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {            
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              setAddPlatform({...addPlatform, dialog: false})
              setUserDetail({...userDetail, dialog: false})
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }

  const onSubmitPricebookAdd = async (e) => {
    e.preventDefault()
    try {
      let Headers = {
        headers: {
          Authorization: `Bearer ${UserToken}`
        },
      };

      let body = {
        user_id: addPricebook.user_id,
        platform_child_id: addPricebook.platform_child_id,
        level: addPricebook.level,
        post_submision_price: addPricebook.post_submision_price,
        submision_status: addPricebook.submision_status,
      }      

      const response = await Axios.post(`${API}/admin/influencer/pricebook`, body, Headers)
      .then((res) => {   
          if(res.data.status === "success") {            
            swal("Success!", `${res.data.message}`, "success");
            setTimeout(() => {
              swal.close()
              setAddPricebook({...addPricebook, dialog: false})
              setUserDetail({...userDetail, dialog: false})
              window.location.reload()
            }, 1000);  
          }   
      }).catch((err) => {
        swal("Something Wrong!", `${err.response.data.message}`, "error");
      })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  }


  const onSubmitChangeImage = async(e) => {
    e.preventDefault()
    if(changeImage.image.name) {
      try {
        let formdata = new FormData();
        
        formdata.append("file", changeImage.image);
        formdata.append("email", 'admin@mediacartz.com');
        formdata.append("password", 123456);
        
        const uploadimg = await Axios.post(`https://cdn.mediacartz.com/api/store`, formdata)
        .then((res) => {                
            let url = res.data.data.url 
            let formdata = new FormData();
        
            formdata.append("user_id", userDetail.id);
            formdata.append("imageUrl", url);
            
            Axios.post(`${API}/admin/influencer/profile`, formdata, {
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${UserToken}`
                }
            })
            .then((res) => {     
                swal("Success!", `${res.data.message}`, "success");
                setTimeout(() => {
                    swal.close()
                    window.location.reload()
                }, 1000);
            }).catch((err) => {
              swal("Something Wrong!", `${err.response.data.message}`, "error");
            })  
            
        }).catch((err) => {
            swal("Something Wrong!", `${err.response.data.message}`, "error");
        })
        return uploadimg
      } catch (error) {
        swal("Something Wrong!", `${error}`, "error");
      }
    } else {
      swal("Something Wrong!", "Please choose image first", "error");
    }
  }
  
  const renderInfluencer = () => {
    if(user) {
      return (
        <>
        {user.map((data, index) => (
          <TableRow
            className={classes.tableRow}
            hover
            key={data.id}
          >
            <TableCell>
            { dataUser.page > 1 ? 
                  <>
                    { index + 1 + (dataUser.perPage  * (dataUser.page - 1) ) }
                  </>
                  : 
                  <>
                  {index  + 1}
                  </>
              }
            </TableCell>
            <TableCell>
              <div className={classes.nameContainer}>
                {data.profile === null ?                 
                <Avatar
                  className={classes.avatar}
                  src={img}
                >                          
                </Avatar>
                : 
                <Avatar
                  className={classes.avatar}
                  src={data.profile.imageUrl === null ? img : data.profile.imageUrl }
                >                          
                </Avatar>
                }
                <Typography variant="body1">{data.fullname}</Typography>
              </div>
            </TableCell>
            <TableCell>
              {data.email} 

              {data.status !== "NOT ACTIVE" ?
                <CheckCircleOutlineIcon style={{color:'#00e676', paddingLeft: '7px', fontSize: '30px'}}/>
                :
                <HighlightOffIcon style={{color:'#000', paddingLeft: '7px', fontSize: '30px'}}/>                
              }

            </TableCell>
            <TableCell>{data.company === null ? '-' : data.company.company}</TableCell>
            <TableCell>{data.phone_number ? data.phone_number : 'Null'}</TableCell>
            <TableCell>
              { data.status === "SUSPEND" ?
                <Chip
                  size="small"
                  label="SUSPEND"
                  icon={<BlockIcon style={{color: '#fff'}} />}
                  style={{backgroundColor: '#c62828', color: 'white'}}
                />
                :
                data.status === "NOT ACTIVE" ?
                <Chip
                  size="small"
                  label="NOT ACTIVE"
                  icon={<ClearIcon style={{color: '#fff'}} />}
                  style={{backgroundColor: '#424242', color: 'white'}}
                /> 
                : 
                <Chip
                  size="small"
                  label="ACTIVE"
                  icon={<DoneIcon style={{color: '#fff'}} />}
                  style={{backgroundColor: '#00e676', color: 'white'}}
                />         
              }
            </TableCell>
            <TableCell >
              <Grid container direction='row' >
                <Box
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setUserDetail({...userDetail,
                      fullname: data.fullname,
                      email: data.email,
                      created_at: data.created_at,
                      status: data.status,
                      phone_number: data.phone_number === null ? '-' : data.phone_number,
                      company: data.company === null ? "" : data.company,
                      profile: data.profile === null ? "" : data.profile,
                      pricebook: data.pricebook === null ? "" : data.pricebook,
                      platforms: data.platforms === null ? "" : data.platforms,
                      id: data.id,
                      dialog: true
                    })
                  }}
                >
                  <VisibilityIcon fontSize="large"  />
                </Box>

                <Box style={{cursor: 'pointer', paddingLeft: '5px'}}
                  onClick={() => {
                    if(data.status !== "SUSPEND") {
                      swal({
                      title: "Warning",
                      text: "Are you sure want to Suspend this User?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    })
                    .then((willDelete) => {
                      if (willDelete) {
                        let id_user = data.id

                        let Headers = {
                          headers: {
                            Authorization: `Bearer ${UserToken}`
                          },
                        };

                        let body = {
                          status: "SUSPEND",
                          id : id_user
                        }

                        try {
                        Axios.put(`${API}/admin/user`, body, Headers)
                        .then((res) => {   
                          if(res.data) {
                            swal("Poof! User Succesfully Suspended!", {
                                icon: "success",
                              });   
                              setTimeout(() => {
                                swal.close()
                                window.location.reload()
                              }, 1000); 
                              
                            }     
                          }).catch((err) => {
                            swal("Something Wrong", `${err.response.data.message}`, "error");
                          })

                        } catch(error) {
                          swal("Something Wrong", `${error}`, "error");
                        }
                        
                      } else {
                        swal.close()
                      }
                    });
                    } else {
                      swal({
                      title: "Warning",
                      text: "Are you sure want to Activate this User again?",
                      icon: "success",
                      buttons: true,
                      dangerMode: true,
                    })
                    .then((willDelete) => {
                      if (willDelete) {
                        let id_user = data.id

                        try {

                        let Headers = {
                          headers: {
                            Authorization: `Bearer ${UserToken}`
                          },
                        };

                        let body = {
                          status: "ACTIVE",
                          id : id_user
                        }
                        Axios.put(`${API}/admin/user`, body, Headers )
                        .then((res) => {   
                          if(res.data) {
                            swal("Poof! User Succesfully Activated!", {
                                icon: "success",
                              });   
                              setTimeout(() => {
                                swal.close()
                                window.location.reload()
                              }, 1000); 
                              
                            }     
                          }).catch((err) => {
                            swal("Something Wrong", `${err.response.data.message}`, "error");
                          })

                        } catch(error) {
                          swal("Something Wrong", `${error}`, "error");
                        }
                        
                      } else {
                        swal.close()
                      }
                    });
                    }
                  }}               
                >
                  <ErrorOutlineIcon fontSize="large" style={{color: 'red'}} />
                </Box>
              </Grid>
              
            </TableCell>  
          </TableRow>
        ))}
        </>
      )
    }
  }

  return (
    <React.Fragment>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="5px">No</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Phone</TableCell>                        
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderInfluencer()} 
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={dataUser.total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={10}
          />
        </CardActions>
      </Card>

      {/* Start Modal DETAIL */}
      <Dialog
        open={userDetail.dialog} 
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Detail User Influencer</DialogTitle>
          <DialogContent>
            <Grid container direction="row" justify="center" >
              <Box pb={2}> 
              {userDetail.profile === null ?                 
                <Avatar
                  className={classes.avatarImg}
                  src={img}
                >                          
                </Avatar>
                : 
                <Avatar
                  className={classes.avatarImg}
                  src={userDetail.profile.imageUrl === null ? img : userDetail.profile.imageUrl }
                >                          
                </Avatar>
                }
               </Box>
            </Grid>
            <Grid container direction="row" justify="center" >
              <Box 
                fontSize={15}
                color="blue"
                fontWeight="bold"
                style={{cursor: 'pointer'}}    
                onClick={() => setChangeImage({...changeImage, dialog: true}) } 
                pb={1}         
              >Change Image</Box>
            </Grid>
            <Grid container direction="row" justify="center" >
              <Container maxWidth='sm' >
                <Paper elevation={3} style={{borderRadius: '10px'}}>
                <Box p={2}>
                  <Grid container direction="row" justify="space-between" >
                    <Grid>
                      <Box fontSize={20} fontWeight="bold" pb={1} >Basic Info</Box></Grid>
                      <Grid>
                        <Button 
                          onClick={() => setEditBasicProfile({
                            ...editBasicProfile, 
                            dialog: true,
                            id: userDetail.id,
                            fullname: userDetail.fullname,
                            phone_number: userDetail.phone_number,
                            
                          })} 
                          variant="contained" color="primary" size="small"
                          style={{borderRadius: '15px'}}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={5} >Full Name</Grid>
                    <Grid item lg={7} >: {userDetail.fullname}</Grid>
                  </Grid>
                  
                  <Grid container spacing={2}>
                    <Grid item lg={5} >Email</Grid>
                    <Grid item lg={7} >: {userDetail.email}</Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item lg={5} >Phone</Grid>
                    <Grid item lg={7} >: {userDetail.phone_number === "" ? '-' : userDetail.phone_number }</Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item lg={5} >Status</Grid>
                    <Grid item lg={7} >: 
                        { userDetail.status === "SUSPEND" ?
                          <Chip
                            size="small"
                            label="SUSPEND"
                            icon={<BlockIcon style={{color: '#fff'}} />}
                            style={{backgroundColor: '#c62828', color: 'white'}}
                          />
                          :
                          userDetail.status === "NOT ACTIVE" ?
                          <Chip
                            size="small"
                            label="NOT ACTIVE"
                            icon={<ClearIcon style={{color: '#fff'}} />}
                            style={{backgroundColor: '#424242', color: 'white'}}
                          /> 
                          : 
                          <Chip
                            size="small"
                            label="ACTIVE"
                            icon={<DoneIcon style={{color: '#fff'}} />}
                            style={{backgroundColor: '#00e676', color: 'white'}}
                          />         
                        }
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item lg={5} >Created At</Grid>
                    <Grid item lg={7} >: {userDetail.created_at }</Grid>
                  </Grid>
                </Box>
                </Paper>
              </Container>
            </Grid>

            <Grid container spacing={2} style={{paddingTop: '30px'}}>
              <Grid item lg={6}>
                <Paper elevation={2} style={{borderRadius: '10px'}}>
                  <Box p={2}>                    
                    <Grid container direction="row" justify="space-between" >
                      <Grid><Box fontSize={20} fontWeight="bold" >Profile</Box></Grid>
                      <Grid>
                        <Button 
                          onClick={() => {
                            if(userDetail.profile === "") {
                              setEditProfile({
                                ...editProfile, 
                                dialog: true,
                                user_id: "",
                                bio: "",
                                place_bod: "",
                                birthdays: "",
                                gender: "",
                                address: "",
                              })
                            } else {
                              setEditProfile({
                                ...editProfile, 
                                dialog: true,
                                user_id: userDetail.profile.user_id,
                                bio: userDetail.profile.bio,
                                place_bod: userDetail.profile.place_bod,
                                birthdays: userDetail.profile.birthdays === "Invalid date" ? "" : new Date(userDetail.profile.birthdays),
                                gender: userDetail.profile.gender,
                                address: userDetail.profile.address,
                              })
                            }
                          }} 
                          variant="contained" color="primary" size="small"
                          style={{borderRadius: '15px'}}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                    <Box pl={2}>
                      <Grid container spacing={2} style={{paddingTop: '10px'}}>
                        <Grid item lg={4} >Bio</Grid>
                        <Grid item lg={6} >{userDetail.profile === "" ? "-" : userDetail.profile.bio }</Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={4} >Domisili</Grid>
                        <Grid item lg={6} >{userDetail.profile === "" ? "-" : userDetail.profile.place_bod }</Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={4} >Birthdays</Grid>
                        <Grid item lg={6} >{ userDetail.profile === "" ? "-" : moment(userDetail.profile.birthdays).format("DD MMMM YYYY") }</Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={4} >Address</Grid>
                        <Grid item lg={6} >{userDetail.profile === "" ? "-" : userDetail.profile.address}</Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={4} >Gender</Grid>
                        <Grid item lg={6} >{userDetail.profile === "" ? "-" : userDetail.profile.gender }</Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item lg={6}>
                <Paper elevation={2} style={{borderRadius: '10px'}}>
                  <Box p={2}>                    
                    <Grid container direction="row" justify="space-between" >
                      <Grid><Box fontSize={20} fontWeight="bold" >Company</Box></Grid>
                      
                    </Grid>
                    <Box pl={2}>
                      <Grid container spacing={2} style={{paddingTop: '10px'}}>
                        <Grid item lg={4} >Company</Grid>
                        <Grid item lg={6} >{userDetail.company.company }</Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={4} >Email</Grid>
                        <Grid item lg={6} >{userDetail.company.email }</Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={4} >Contact</Grid>
                        <Grid item lg={6} >{userDetail.company.contact }</Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={4} >Address</Grid>
                        <Grid item lg={6} >{userDetail.company.address }</Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={4} >Company Fee</Grid>
                        <Grid item lg={6} >{userDetail.company.fee }%</Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item lg={6}>
                <Paper elevation={2} style={{borderRadius: '10px'}}>
                  <Box p={2}>                    
                    <Grid container direction="row" justify="space-between" >
                      <Grid><Box fontSize={20} fontWeight="bold" >Platforms</Box></Grid>
                      <Grid>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          size="small"
                          onClick={() => {
                            setAddPlatform({...addPlatform, dialog: true, user_id: userDetail.id})
                          }}
                          style={{borderRadius: '15px'}}
                        > 
                          Add
                        </Button>
                      </Grid>

                    </Grid>
                    <Box pl={2}>
                      <Box pt={1}></Box>
                      {userDetail && userDetail.platforms.map((data, index) => (
                        <Grid container spacing={2} style={{paddingTop: '10px'}} key={index}>
                          <Grid item lg={3} >
                            {data.platform.platform_name} {" "} 
                            
                            <Grid container direction="row"  >
                              <Box 
                                style={{color: 'blue', cursor:'pointer', fontSize: '15px', paddingRight: '5px'}} 
                                onClick={() => setEditPlatform({...editPlatform, 
                                  username: data.username,
                                  followers: data.followers,
                                  id: data.id,
                                  dialog: true
                                })}
                              >Edit</Box> 
                            </Grid>

                          </Grid>
                          <Grid item lg={7} > 
                            <Box>Username - <strong>@{data.username} 
                            <a target="_blank" rel="noreferrer" href={ 
                              data.platform.platform_name === "Instagram" ? `https://www.instagram.com/${data.username}` : 
                              data.platform.platform_name === "Facebook" ? `https://www.facebook.com/${data.username}` : data.platform.platform_name === "Twitter" ? `https://www.twitter.com/${data.username}`: data.platform.platform_name === "Youtube" ? `https://www.youtube.com/${data.username}` : `https://www.instagram.com/${data.username}` } ><LaunchIcon/></a>
                             </strong></Box> 
                            <Box>Follower - <strong>{data.followers.toLocaleString()}</strong></Box> 
                          </Grid>
                        </Grid>
                      ))}
                      
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item lg={6}>
                <Paper elevation={2} style={{borderRadius: '10px'}}>
                  <Box p={2}>                    
                    <Grid container direction="row" justify="space-between" >
                      <Grid><Box fontSize={20} fontWeight="bold" >Pricebook</Box></Grid>
                      <Grid>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          size="small"
                          onClick={() => {
                            if(userDetail.platforms.length !== 0) {
                              setAddPricebook({...addPricebook, 
                              dialog: true, 
                              user_id: userDetail.id,
                              platforms: userDetail.platforms
                            })
                            } else {
                              swal("Something Wrong!", "Please at least add one Platform!", "error");
                            }
                          }}
                          style={{borderRadius: '15px'}}
                        > 
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                    <Box pl={2}>
                      <Box pt={1}></Box>
                      {userDetail && userDetail.pricebook.map((data, index) => (
                        <Grid container spacing={2} style={{paddingTop: '10px'}} key={index}>
                          <Grid item lg={6} >
                            {data.platformChild.name} {" "}
                            <Grid container direction='row'>
                              <Box 
                                style={{color: 'blue', cursor:'pointer', fontSize: '15px', paddingRight: '5px'}} 
                                onClick={() => setEditPricebook({...editPricebook, 
                                  ppc_submision_price: data.ppc_price,
                                  post_submision_price: data.post_price,
                                  platform_child: data.platformChild.name,                                
                                  id: data.id,
                                  level: data.level,
                                  dialog: true
                                })}
                              >Edit</Box> |

                              <Box 
                                style={{color: '#ee4054', cursor:'pointer', fontSize: '15px', paddingLeft: '5px'}} 
                                onClick={() => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Once deleted, you will not be able to recover this imaginary file!",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  })
                                  .then((willDelete) => {
                                    if (willDelete) {
                                      let id_pricebook = data.id

                                      try {
                                      Axios.delete(`${API}/admin/influencer/pricebook`, {
                                        headers: {                    
                                          Authorization: `Bearer ${UserToken}`
                                        },
                                        data: {
                                          id: id_pricebook
                                        }
                                      })
                                      .then((res) => {   
                                        if(res.data) {
                                          swal("Poof! Data Pricebook has been deleted!", {
                                              icon: "success",
                                            });   
                                            setTimeout(() => {
                                              swal.close()
                                              window.location.reload()
                                            }, 1000); 
                                            
                                          }     
                                        }).catch((err) => {
                                          swal("Something Wrong", `${err.response.data.message}`, "error");
                                        })

                                      } catch(error) {
                                        swal("Something Wrong", `${error}`, "error");
                                      }
                                      
                                    } else {
                                      swal.close()
                                    }
                                  });
                                }}
                              >Delete</Box>
                            </Grid>
                          </Grid>
                          <Grid item lg={6} > 
                            <Box style={{textTransform: 'capitalize'}}>Rp.<strong>{data.post_price.toLocaleString()}</strong></Box> 
                            {/* <Box>PPC = Rp.<strong>{data.ppc_price.toLocaleString()}</strong></Box>  */}
                          </Grid>
                        </Grid>
                      ))}
                      
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              
            </Grid>
            
            
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUserDetail({...userDetail, dialog: false})} color="primary" variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        
      </Dialog>
      {/* End Modal DETAIL */}

      {/* Start Modal Edit */}
      <Dialog 
        open={editProfile.dialog} 
        aria-labelledby="form-dialog-title"
      >
        <Form onSubmit={onSubmitProfileEdit} validate >
          <DialogTitle id="form-dialog-title">Edit Profile</DialogTitle>
          <DialogContent>            
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={3}
                variant="outlined"
                label="Bio"
                fullWidth
                defaultValue={editProfile.bio}
                onChange={handleChangeEditProfile('bio')}
                required
              />
                        
              <TextField
                id="outlined-multiline-static"
                style={{marginTop: '20px'}}
                multiline
                rows={3}
                variant="outlined"
                label="Address"
                fullWidth
                defaultValue={editProfile.address}
                onChange={handleChangeEditProfile('address')}
                required
              />            
            
              <TextField
                margin="dense"
                id="name"
                label="Domisili"
                style={{marginTop: '20px'}}
                type="text" 
                variant="outlined"
                fullWidth
                onChange={handleChangeEditProfile('place_bod')}
                defaultValue={editProfile.place_bod}
                required
              />
                        
              <FormControl variant="outlined"  fullWidth className={classes.formControl} style={{marginTop: '10px'}} >
                <Box pb={1}>Choose Gender</Box>
                <Select
                  native
                  onChange={handleChangeEditProfile('gender')}
                  inputProps={{
                    name: 'Choose Gender',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option>{editProfile.gender}</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>               
                  
                </Select>
              </FormControl>

              <div className="pt-2">
                <Box pb={1}>Birthday</Box>
                <DatePicker
                  className="customDatePicker"
                  onChange={(e) => setEditProfile({...editProfile, birthdays: e}) }
                  value={editProfile.birthdays}
                  format="dd/M/y"
                />
              </div>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditProfile({...editProfile, dialog: false}) } color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Edit */}

      {/* Start Modal Edit Basic Profile*/}
      <Dialog 
        open={editBasicProfile.dialog} 
        aria-labelledby="form-dialog-title"
      >
        <Form onSubmit={onSubmitBasicProfileEdit} validate >
          <DialogTitle id="form-dialog-title">Edit Basic Profile</DialogTitle>
          <DialogContent>
            
              <TextField
                style={{marginBottom: '17px'}}
                multiline
                variant="outlined"
                label="Full Name"
                fullWidth
                defaultValue={editBasicProfile.fullname}
                onChange={handleChangeEditBasicProfile('fullname')}
                required
              />

              <TextField
                style={{marginBottom: '17px'}}
                multiline
                variant="outlined"
                label="Phone Number"
                fullWidth
                defaultValue={editBasicProfile.phone_number}
                onChange={handleChangeEditBasicProfile('phone_number')}
                required
              />

            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Change Password (optional)</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={editBasicProfile.showPassword ? 'text' : 'password'}
                value={editBasicProfile.password}
                name="password"
                onChange={handleChangeEditBasicProfile('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {editBasicProfile.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditBasicProfile({...editBasicProfile, dialog: false}) } color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Edit Basic Profile */}

      {/* Start Platform Add */}
      <Dialog 
        open={addPlatform.dialog}  
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <Form onSubmit={onSubmitPlatformAdd} validate >
          <DialogTitle id="form-dialog-title">Add Platform Account</DialogTitle>
          <DialogContent>
            <Box pb={1} pt={1}>
              <FormControl variant="outlined"  fullWidth className={classes.formControl}>
                <InputLabel htmlFor="outlined">Choose Platform</InputLabel>
                <Select
                  native
                  onChange={(e) => setAddPlatform({...addPlatform, platform_id : e.target.value})}
                  inputProps={{
                    name: 'Choose Platform',
                    id: 'outlined',
                  }}
                >
                  <option>Select Platform</option>
                  { dataPlatform && dataPlatform.map((data, index) => (
                    <option value={data.id}>{data.platform_name}</option>
                  )) }
                  
                </Select>
              </FormControl>
            </Box>
            <Box pb={1} pt={1}>
              <TextField
                variant="outlined"
                label="Username"
                type="text"
                fullWidth
                onChange={(e) => setAddPlatform({...addPlatform, username : e.target.value})}
                required
              />
            </Box>
            <Box pb={1} pt={1}>
              <TextField
                variant="outlined"
                type="number"
                label="Followers"
                fullWidth
                onChange={(e) => setAddPlatform({...addPlatform, followers : e.target.value})}
                required
              />
            </Box>
        
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddPlatform({...addPlatform, dialog: false}) } color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Platform Add */}

      {/* Start Modal Edit PLATFORM */}
      <Dialog 
        open={editPlatform.dialog} 
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <Form onSubmit={onSubmitPlatformEdit} validate >
          <DialogTitle id="form-dialog-title">Edit Platform Account</DialogTitle>
          <DialogContent>
            
              <TextField
                id="outlined-multiline-static"
                variant="outlined"
                style={{marginBottom: '20px'}}
                label="Username"
                fullWidth
                defaultValue={editPlatform.username}
                onChange={handleEditPlatform('username')}
                required
              />
            
            
              <TextField
                id="outlined-multiline-static"
                variant="outlined"
                label="Followers"
                fullWidth
                type="number"
                defaultValue={editPlatform.followers}
                onChange={handleEditPlatform('followers')}
                required
              />            
            
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditPlatform({...editPlatform, dialog: false}) } color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal PLATFORM */}


      {/* Start Platform Add */}
      <Dialog 
        open={addPricebook.dialog} 
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <Form onSubmit={onSubmitPricebookAdd} validate >
          <DialogTitle id="form-dialog-title">Add Pricebook Account</DialogTitle>
          <DialogContent>
            <Box pb={1} pt={1}>
              <FormControl variant="outlined"  fullWidth className={classes.formControl}>
                <InputLabel htmlFor="outlined">Choose Platform</InputLabel>
                <Select
                  native
                  onChange={(e) => handleChangePlatform(e.target.value)}
                  inputProps={{
                    name: 'Choose Platform',
                    id: 'outlined',
                  }}
                >
                  <option>Select Platform</option>
                  { addPricebook && addPricebook.platforms.map((data, index) => (
                    <option value={data.platform.id}>{data.platform.platform_name}</option>
                  )) }
                  
                </Select>
              </FormControl>
            </Box>

            <Box pb={1} pt={1}>
              <FormControl variant="outlined"  fullWidth className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">Choose Platform Child</InputLabel>
                <Select
                  native
                  // value={state.age}
                  onChange={(e) => setAddPricebook({...addPricebook, platform_child_id : e.target.value})}
                  inputProps={{
                    name: 'Choose Platform Child',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None"/>
                  {dataPlatformChild && dataPlatformChild.map((data, index) => (
                    <option key={index} value={data.id}>{data.name}</option>
                  ))}                  
                </Select>
              </FormControl>
            </Box>
            
            <Box pb={1} pt={1}>
              <TextField
                variant="outlined"
                type="number"
                label="Price"
                fullWidth
                onChange={(e) => setAddPricebook({...addPricebook, post_submision_price : e.target.value})}
                required
              />
            </Box>
        
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddPricebook({...addPricebook, dialog: false}) } color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Platform Add */}

      {/* Start Modal Edit PRICEBOOK */}
      <Dialog 
        open={editPricebook.dialog} 
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <Form onSubmit={onSubmitPricebookEdit} validate >
          <DialogTitle id="form-dialog-title">Edit Pricebook {editPricebook.platform_child}</DialogTitle>
          <DialogContent>
            
              <TextField
                id="outlined-multiline-static"
                variant="outlined"
                label="Price per Post"
                fullWidth
                type="number"
                defaultValue={editPricebook.post_submision_price}
                onChange={handleEditPricebook('post_submision_price')}
                required
              />       

              {/* <TextField
                id="outlined-multiline-static"
                variant="outlined"
                label="Price per Click"
                fullWidth
                defaultValue={editPricebook.ppc_submision_price}
                onChange={handleEditPricebook('ppc_submision_price')}
                required
              />      */}
            
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditPricebook({...editPricebook, dialog: false}) } color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal PRICEBOOK */}

      {/* Start Modal Change Image */}
      <Dialog 
        open={changeImage.dialog} 
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <Form onSubmit={onSubmitChangeImage} validate >
          <DialogTitle id="form-dialog-title">Change Image</DialogTitle>
          <DialogContent>
            
            <Box pt={1}>
                <input 
                    accept="image/*" id="icon-button-file" type="file"                      
                    multiple
                    onChange={(e) => setChangeImage({...changeImage, image: e.target.files[0]}) }
                />
            </Box>    
              
            
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setChangeImage({...changeImage, dialog: false}) } color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      {/* End Modal Change Image */}
    
      
    </React.Fragment>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
