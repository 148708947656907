import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  const isAuthenticated = () => {
      if(typeof window == 'undefined') {
          return false
      }

      if(localStorage.getItem('_%adcp%')) {
          return localStorage.getItem('_%adcp%')
      } else {
          return false
      }
  }

  return (
    <Route 
        {...rest} 
        render={props => {
            if(isAuthenticated()) {
                return  <Layout> <Component {...props} /></Layout>
            }
            else {
              return   <Redirect 
                          to={{
                              pathname: "/",
                              state: { from: props.location }
                          }}
                      />
            }
        
        } }
    />
    
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
