import Axios from 'axios'
import { API, UserToken } from './../../config'

import { 
    FETCH_PLATFORM_REQUEST, 
    FETCH_PLATFORM_SUCCESS, 
    FETCH_PLATFORM_FAILED,
    ADD_PLATFORM_REQUEST,
    ADD_PLATFORM_FAILED,
} from './PlatformTypes'


const fetchPlatformRequest = () => {
    return {
        type: FETCH_PLATFORM_REQUEST
    }
}

const fetchPlatformSuccess = ( data ) => {
    return {
        type: FETCH_PLATFORM_SUCCESS,
        payload: data
    }
}

const fetchPlatformFailed = ( message ) => {
    return {
        type: FETCH_PLATFORM_FAILED,
        payload: message
    }
}

export const fetchPlatform = () => {

    return (
        ( dispatch ) => {
            dispatch(fetchPlatformRequest())
            Axios.get(`${API}/admin/platform/gets`, {
                headers: {                    
                    Authorization: `Bearer ${UserToken}`
                }
            })
            .then((res) => {
                console.log(res, 'response fetch platform')
                dispatch(fetchPlatformSuccess(res.data.results))
            }).catch((err) => {
                dispatch(fetchPlatformFailed(err.message))
            })
        }
    )
}



const addPlatformRequest = () => {
    return {
        type: ADD_PLATFORM_REQUEST
    }
}

const addPlatformFailed = ( message ) => {
    return {
        type: ADD_PLATFORM_FAILED,
        payload: message
    }
}

export const addPlatform = ( input ) => {
    var formdata = new FormData();
    // console.log(input.image, 'ini imageee')
    // let imagePlatform = input.image
    var Headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${UserToken}`
      },
    };
    formdata.append("file", input.image);
    formdata.append("name", input.name);
    formdata.append("url", input.url);    

    return (
        ( dispatch ) => {
            dispatch(addPlatformRequest())
            Axios.post(`${API}/admin/platform`, formdata, Headers)
            .then((res) => {
                console.log(res ,'response add platform')
                dispatch(fetchPlatformRequest())
                Axios.get(`${API}/admin/platform/gets`, {
                    headers: {                    
                        Authorization: `Bearer ${UserToken}`
                    }
                })
                .then((res) => {
                    dispatch(fetchPlatformSuccess(res.data.results))
                    window.location.reload()
                }).catch((err) => {
                    dispatch(fetchPlatformFailed(err.message))
                })
            }).catch((err) => {
                dispatch(addPlatformFailed(err.message))
            })
        }
    )
}










