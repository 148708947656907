import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  TotalInfluencers,
  TotalAdmin,
  TotalCompany,
  LatestInfluencer,
  DetailCompany
} from './components';
import Axios from "axios";
import { API, UserToken, UserName, UserRole } from '../../config'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  const [dataUser, setDataUser] = useState([]);
  const [dataAdmin, setDataAdmin] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);

  useEffect(()=>{    
    const loadInfluencer = () => {
        // setLoading(true)
            Axios.get(`${API}/admin/influencer?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setDataUser(res.data.results.total)              
                // setLoading(false)
            }).catch((err) => {
                console.log(err)
                // setLoading(false)
            })        
    }

    const loadAdminUser = () => {
      // setLoading(true)
          Axios.get(`${API}/admin/user?page=1&rows=10`, {
            headers: {                    
                Authorization: `Bearer ${UserToken}`
            }
          })
          .then((res) => {
              setDataAdmin(res.data.results.total)              
              // setLoading(false)
          }).catch((err) => {
              console.log(err)
              // setLoading(false)
          })        
    }

    const loadCompany = () => {
      // setLoading(true)
          Axios.get(`${API}/admin/company?page=1&rows=10`, {
            headers: {                    
                Authorization: `Bearer ${UserToken}`
            }
          })
          .then((res) => {
              setDataCompany(res.data.results.total)              
              // setLoading(false)
          }).catch((err) => {
              console.log(err)
              // setLoading(false)
          })        
    }
    loadCompany()
    loadAdminUser()
    loadInfluencer()
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <h3 >Welcome Back, <span style={{textTransform: 'capitalize', color: '#7c71d5'}}>{UserName}</span></h3> 
        </Grid>
        <Grid
          item
          lg={4}
          sm={6}
          xl={4}
          xs={12}
        >
          <TotalInfluencers data={dataUser && dataUser} />
        </Grid>
        <Grid
          item
          lg={4}
          sm={6}
          xl={4}
          xs={12}
        >
          <TotalAdmin data={dataAdmin && dataAdmin} />
        </Grid>
        <Grid
          item
          lg={4}
          sm={6}
          xl={4}
          xs={12}
        >
          <TotalCompany data={dataCompany && dataCompany} />
        </Grid>
        
        {UserRole === "Admin" ? 
          <Grid
            item
            lg={4}
            md={4}
            xl={4}
            xs={12}
          >
            <DetailCompany />
          </Grid> : null
        }

        <Grid
          item
          lg={4}
          md={4}
          xl={4}
          xs={12}
        >
          <LatestInfluencer />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
