import {
    FETCH_INTEREST_REQUEST,
    FETCH_INTEREST_SUCCESS,
    FETCH_INTEREST_FAILED,
    ADD_INTEREST_FAILED,
    ADD_INTEREST_REQUEST,
    UPDATE_INTEREST_REQUEST,
    UPDATE_INTEREST_FAILED,
    DELETE_INTEREST_REQUEST,
    DELETE_INTEREST_FAILED  
    
} from './InterestTypes'

const initialState = {
    loading: false,
    interest_data: [],
    message: ''
}
 
const interestReducers = ( state = initialState, action ) => {
    switch(action.type) {
        case FETCH_INTEREST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_INTEREST_SUCCESS:
            return {
                ...state,
                interest_data: action.payload,
                loading: false
            }
        case FETCH_INTEREST_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }
        case ADD_INTEREST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_INTEREST_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }
        case UPDATE_INTEREST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_INTEREST_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }
        case DELETE_INTEREST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_INTEREST_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }        
        
        default: return state
    }
}

export default interestReducers