import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import Axios from "axios";
import { API, UserToken } from '../../../../config'
import swal from 'sweetalert';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DetailCompany = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [dataCompany, setDataCompany] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(()=>{    
    const loadCompany = async () => {
      // setLoading(true)
      try {
        const response = await Axios.get(`${API}/admin/company?page=1&rows=1`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
            setDataCompany(res.data.results)              
            // setLoading(false)
            setDataLoaded(true)
        }).catch((err) => {
            swal("Something Wrong!", `${err}`, "error");
            // setLoading(false)
        }) 
        return response;
      } catch (error) {
        swal("Something Wrong!", `${error}`, "error");
      }       
    }
    loadCompany()
  }, []);   

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader        
        title="Company Information"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
         
            <ListItem >
              <Grid container >
                <Grid item lg={4}>
                  <ListItemText primary="Company Name" /> 
                </Grid>
                <Grid item lg={6}>
                  <ListItemText primary={dataLoaded && dataCompany.data[0].company} /> 
                </Grid>                
              </Grid>
            </ListItem>

            <ListItem >
              <Grid container >
                <Grid item lg={4}>
                  <ListItemText primary="Email" /> 
                </Grid>
                <Grid item lg={6}>
                  <ListItemText primary={dataLoaded && dataCompany.data[0].email} /> 
                </Grid>                
              </Grid>
            </ListItem>

            <ListItem >
              <Grid container >
                <Grid item lg={4}>
                  <ListItemText primary="Address" /> 
                </Grid>
                <Grid item lg={6}>
                  <ListItemText primary={dataLoaded && dataCompany.data[0].address} /> 
                </Grid>                
              </Grid>
            </ListItem>

            <ListItem >              
              <Grid container >
                <Grid item lg={4}>
                  <ListItemText primary="Contact Person" /> 
                </Grid>
                <Grid item lg={6} >
                  <ListItemText primary={dataLoaded && dataCompany.data[0].contact} /> 
                </Grid>                
              </Grid>
            </ListItem>
         
        </List>
      </CardContent>
      <Divider />
      
    </Card>
  );
};

DetailCompany.propTyps = {
  className: PropTypes.string
};

export default DetailCompany;
