import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Paper
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Chip from '@material-ui/core/Chip';
import swal from 'sweetalert'
import Axios from "axios";
import { API, UserToken } from '../../../../config'
import moment from 'moment'
// import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  AvatarSizeOne: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRight: '2px solid #ef8745',
    borderLeft: '2px solid #c24770',
    borderTop: '2px solid #c24770',
    borderBottom: '2px solid #ef8745',
  },
}));

const CampaignList = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();

  const [campaign, setcampaign] = useState([]);
  const [dataCampaign, setDatacampaign] = useState([]);
  const [detailCampaign, setDetailCampaign] = useState({
    id: "",
    project_name: "",
    owner_brand: "",
    owner_email: "",
    owner_contact: "",
    confirm_budget: "",
    description: "",
    link_content: "",
    payment_type: "",
    status: "",
    order_desc: "",
    expire_date: "",
    interest : "",
    platform_child: "",  
    order_influencer: [],  
    dialog: false
  })

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
    loadCampaignPaginate(page)
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const DataStatus = [
    {
      id: '',
      name: 'ALL'
    }, 
    {
      id: 'OPEN',
      name: 'OPEN'
    },  
    {
      id: 'RUNNING',
      name: 'RUNNING'
    },
    {
      id: 'CLOSE',
      name: 'CLOSE'
    },    
    
  ]

  const renderStatus = () => {
    if(DataStatus) {
      return (
        <>
        
        {DataStatus.map(data => (
          <option
            key={data.id}
            value={data.id}
          >
            {data.name}
          </option>
          
        ))} 
        </>
      )
    }
  }

  const handleChangeStatus = async(data) => {
    try {      
          
      const response = await Axios.get(`${API}/admin/order?page=1&rows=10&status=${data}`, {
          headers: {                    
              Authorization: `Bearer ${UserToken}`
          }
        })
        .then((res) => {
          setcampaign(res.data.result.data)
          setDatacampaign(res.data.result)
        }).catch((err) => {
          swal("Something Wrong!", `${err}`, "error");
        })
      return response;
    } catch (error) {
      swal("Something Wrong!", `${error}`, "error");
    }
  };

  
  const loadCampaignPaginate = async (page) => {
    
    let page_data = page + 1
    let rows_data = 10

    const response = await  Axios.get(`${API}/admin/order?page=${page_data}&rows=${rows_data}`, {
        headers: {                    
            Authorization: `Bearer ${UserToken}`
        }
      })
      .then((res) => {
          // console.log(res, 'response fetch campaign paginate')
          setcampaign(res.data.result.data)
          setDatacampaign(res.data.result)
      }).catch((err) => {
          console.log(err)
      })
    return response;

  }

  
  useEffect(()=>{    
    const loadCampaign = () => {
      // console.log(data, 'dataaaa klik')
      // setLoading(true)
            Axios.get(`${API}/admin/order?page=1&rows=10`, {
              headers: {                    
                  Authorization: `Bearer ${UserToken}`
              }
            })
            .then((res) => {
                setcampaign(res.data.result.data)
                setDatacampaign(res.data.result)
                // console.log(res, 'response data single')                
                // setLoading(false)
            }).catch((err) => {
                console.log(err)
                // setLoading(false)
            })        
    }
    loadCampaign()
}, []);


  const renderInfluencer = () => {
    if(campaign) {
      return (
        <>
        {campaign.map((data, index) => (
          <TableRow
            className={classes.tableRow}
            hover
            key={data.id}
          >
            <TableCell>
              { dataCampaign.page > 1 ? 
                  <>
                    { index + 1 + (dataCampaign.perPage  * (dataCampaign.page - 1) ) }
                  </>
                  : 
                  <>
                  {index  + 1}
                  </>
              }
            </TableCell>
            <TableCell>{data.project_name}</TableCell>
            <TableCell>{data.owner_brand}</TableCell>
            <TableCell>{data.interest === null ? "-" : data.interest.interest_name }</TableCell>
            <TableCell style={{textTransform: 'capitalize'}}>{data.payment_type}</TableCell>
            <TableCell>{data.platform_child.name}</TableCell>
            <TableCell>Rp.{data.confirm_budget.toLocaleString()}</TableCell>
            <TableCell>
            { data.status === "RUNNING" ?
                <Chip
                  size="small"
                  label="RUNNING"                  
                  style={{backgroundColor: '#2558ab', color: 'white', width: '80px'}}
                />
                :
                data.status === "CLOSE" ?
                <Chip
                  size="small"
                  label="CLOSE"                  
                  style={{backgroundColor: '#424242', color: 'white', width: '80px'}}
                /> 
                : 
                <Chip
                  size="small"
                  label="OPEN"                  
                  style={{backgroundColor: '#00e676', color: 'white', width: '80px'}}
                />         
              }
            </TableCell>
            <TableCell >
              <Box 
                style={{cursor: 'pointer'}}
                onClick={() => setDetailCampaign({
                  ...detailCampaign, 
                  dialog: true,
                  id: data.id,
                  project_name: data.project_name,
                  owner_brand: data.owner_brand,
                  owner_email: data.owner_email,
                  owner_contact: data.owner_contact,
                  confirm_budget: data.confirm_budget,
                  description: data.description,
                  link_content: data.link_content,
                  payment_type: data.payment_type,
                  status: data.status,
                  order_desc: data.order_desc,
                  expire_date: data.expire_date,
                  interest : data.interest.interest_name,
                  platform_child: data.platform_child.name,
                  order_influencer: data.order_influencer
                })}
              >
                <VisibilityIcon fontSize="large"  />
              </Box>              
            </TableCell>       
          </TableRow>
        ))}
        </>
      )
    }
  }

  return (
    <>
    <Box pb={1}>      
      <TextField
        width="300px"
        margin="dense"
        onChange={(e) => handleChangeStatus(e.target.value)}
        value={DataStatus.id}
        required
        select
        SelectProps={{ native: true }}
        variant="outlined"
      >
        {renderStatus()}
      </TextField>
    </Box>
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="5px">No</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Interest</TableCell>
                  <TableCell>Type Payment</TableCell>                  
                  <TableCell>Platform</TableCell>
                  <TableCell>Total Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderInfluencer()}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={dataCampaign.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={10}
        />
      </CardActions>
    </Card>

    <Dialog
        open={detailCampaign.dialog}      
        fullWidth={true}
        maxWidth="lg"  
      >
        <DialogTitle>
          <h4>Detail Campaign</h4>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid xl={7} lg={7} md={7} sm={12} xs={12}>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}} >Project Name</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{detailCampaign.project_name}</h6>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Project Description</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{detailCampaign.order_desc}</h6>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Interest</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{detailCampaign.interest}</h6>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Total Price</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>Rp. {detailCampaign.confirm_budget.toLocaleString()}</h6>
                </Grid>
              </Grid>
              <Box pt={1} pb={1}></Box>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Owner</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{detailCampaign.owner_brand}</h6>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Owner Email</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{detailCampaign.owner_email}</h6>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Owner Contact</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{detailCampaign.owner_contact}</h6>
                </Grid>
              </Grid>
              <Box pt={1} pb={1}></Box>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Type Payment</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{detailCampaign.payment_type}</h6>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Platform</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{detailCampaign.platform_child}</h6>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Image Content</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <Box pt={1} pb={1}>
                      <img alt="link" src={detailCampaign.link_content} width="150px" />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Caption </h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{detailCampaign.description}</h6>
                </Grid>
              </Grid>
              <Box pt={1} pb={1}></Box>
              <Grid container>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                  <h6 style={{color: '#2558ab', fontWeight: 'bold'}}>Expire Date</h6>
                </Grid>
                <Grid xl={8} lg={8} md={8} sm={8} xs={12}>
                  <h6>{moment(detailCampaign.expire_date).format('DD MMMM YYYY ')}</h6>
                </Grid>
              </Grid>
            </Grid>
            <Grid xl={5} lg={5} md={5} sm={12} xs={12}>
              <Box pb={1}><h5>Influencer</h5></Box>
              {detailCampaign.order_influencer.map((data, index) => (
                <Grid item lg={12} xl={12} md={12} key={index} >
                <Box pb={1}>
                  <Paper elevation={3}>
                    <Box p={2} >
                      <Grid container>
                        <Grid lg={4}><Box fontWeight="bold" color="#2558ab" >Influencer Name</Box></Grid>
                        <Grid lg={8}><Box>{data.influencer.fullname}</Box> </Grid>
                      </Grid>
                      <Grid container>
                        <Grid lg={4}><Box fontWeight="bold" color="#2558ab">Price</Box></Grid>
                        <Grid lg={8}><Box>Rp.{data.price.toLocaleString()}</Box> </Grid>
                      </Grid>        
                      <Grid container>
                        <Grid lg={4}><Box fontWeight="bold" color="#2558ab">Status</Box></Grid>
                        <Grid lg={8}>
                        { data.status === "WAITING FOR OWNER" ?
                          <Chip
                            size="small"
                            label="WAITING FOR OWNER"                  
                            style={{backgroundColor: '#f9ca00', color: 'white', width: '200px', textTransform: 'capitalize'}}
                          />
                          :
                          data.status === "WAITING FOR INFLUENCER" ?
                          <Chip
                            size="small"
                            label="WAITING FOR INFLUENCER"                  
                            style={{backgroundColor: '#f9ca00', color: 'white', width: '200px', textTransform: 'capitalize'}}
                          /> 
                          :
                          data.status === "ACCEPTED BY OWNER" ?
                          <Chip
                            size="small"
                            label="ACCEPTED BY OWNER"                  
                            style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                          /> 
                          :
                          data.status === "ACCEPTED BY INFLUENCER" ?
                          <Chip
                            size="small"
                            label="ACCEPTED BY INFLUENCER"                  
                            style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                          /> 
                          :
                          data.status === "NEGOTIATING" ?
                          <Chip
                            size="small"
                            label="NEGOTIATING"                  
                            style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                          /> 
                          : 
                          data.status === "AGREE" ?
                          <Chip
                            size="small"
                            label="AGREE"                  
                            style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                          /> 
                          : 
                          data.status === "RUNNING" ?
                          <Chip
                            size="small"
                            label="RUNNING"                  
                            style={{backgroundColor: '#7367f0', color: 'white', width: '200px'}}
                          /> 
                          : 
                          data.status === "REJECTED BY OWNER" ?
                          <Chip
                            size="small"
                            label="REJECTED BY OWNER"                  
                            style={{backgroundColor: '#e8505b', color: 'white', width: '200px'}}
                          /> 
                          : 
                          data.status === "REJECTED BY INFLUENCER" ?
                          <Chip
                            size="small"
                            label="REJECTED BY INFLUENCER"                  
                            style={{backgroundColor: '#e8505b', color: 'white', width: '200px'}}
                          /> 
                          : 
                          data.status === "CONSOLIDATION" ?
                          <Chip
                            size="small"
                            label="CONSOLIDATION"                  
                            style={{backgroundColor: '#2558ab', color: 'white', width: '200px'}}
                          /> 
                          :
                          data.status === "VERIFY CONTENT" ?
                          <Chip
                            size="small"
                            label="VERIFY CONTENT"                  
                            style={{backgroundColor: '#9742b2', color: 'white', width: '200px'}}
                          /> 
                          : 
                          <Chip
                            size="small"
                            label="DONE"                  
                            style={{backgroundColor: '#192131', color: 'white', width: '200px'}}
                          />         
                        }
                        </Grid>
                      </Grid>              
                      <Grid container>
                        <Grid lg={4}><Box fontWeight="bold" color="#2558ab">Start Posting Date</Box></Grid>
                        <Grid lg={8}><Box>{moment(data.start_date).format('DD MMMM YYYY')}</Box></Grid>
                      </Grid>
                      <Grid container>
                        <Grid lg={4}><Box fontWeight="bold" color="#2558ab">End Posting Date</Box></Grid>
                        <Grid lg={8}><Box>{moment(data.end_date).format('DD MMMM YYYY')}</Box></Grid>
                      </Grid>                     
                      
                    </Box>                          
                  </Paper>                        
                </Box>
              </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailCampaign({...detailCampaign, dialog: false})} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CampaignList.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default CampaignList;
