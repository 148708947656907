import {
    FETCH_PLATFORM_REQUEST,
    FETCH_PLATFORM_SUCCESS,
    FETCH_PLATFORM_FAILED,
    ADD_PLATFORM_FAILED,
    ADD_PLATFORM_REQUEST,
    UPDATE_PLATFORM_REQUEST,
    UPDATE_PLATFORM_FAILED,
    DELETE_PLATFORM_REQUEST,
    DELETE_PLATFORM_FAILED  
    
} from './PlatformTypes'

const initialState = {
    loading: false,
    platform_data: [],
    message: ''
}
 
const platformReducers = ( state = initialState, action ) => {
    switch(action.type) {
        case FETCH_PLATFORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_PLATFORM_SUCCESS:
            return {
                ...state,
                platform_data: action.payload,
                loading: false
            }
        case FETCH_PLATFORM_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }
        case ADD_PLATFORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_PLATFORM_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }
        case UPDATE_PLATFORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_PLATFORM_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }
        case DELETE_PLATFORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_PLATFORM_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false
            }        
        
        default: return state
    }
}

export default platformReducers