export const FETCH_INTEREST_REQUEST = 'FETCH_INTEREST_REQUEST'
export const FETCH_INTEREST_SUCCESS = 'FETCH_INTEREST_SUCCESS'
export const FETCH_INTEREST_FAILED = 'FETCH_INTEREST_FAILED'

export const ADD_INTEREST_REQUEST = 'ADD_INTEREST_REQUEST'
export const ADD_INTEREST_FAILED = 'ADD_INTEREST_FAILED'

export const UPDATE_INTEREST_REQUEST = 'UPDATE_INTEREST_REQUEST'
export const UPDATE_INTEREST_FAILED = 'UPDATE_INTEREST_FAILED'

export const DELETE_INTEREST_REQUEST = 'DELETE_INTEREST_REQUEST'
export const DELETE_INTEREST_FAILED = 'DELETE_INTEREST_FAILED'