import React from 'react';
import { 
  Switch, 
  Redirect,
  Route 
} from 'react-router-dom'
import { RouteWithLayout } from './components'
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts'
import {
  Dashboard as DashboardView,
  PlatformList as PlatformListView,
  UserList as UserListView,
  Company as CompanyView,
  VerifyInfluencer as VerifyInfluencerView,
  Interest as InterestView,
  Campaign as CampaignView,
  Account as AccountView,
  Settings as SettingsView,
  SignIn as SignInView,
  AddUser as AddUserView,
  UserAdmin as UserAdminView,
  Baseprice as BasepriceView,
  NotFound as NotFoundView,  
  ClientList as ClientListView,
  SystemSetting as SystemSettingView,
  MappingUser as MappingUserView,
  CampaignInfluencer as CampaignInfluencerView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/login"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={CompanyView}
        exact
        layout={MainLayout}
        path="/company"
      />
       <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={VerifyInfluencerView}
        exact
        layout={MainLayout}
        path="/verify-account"
      />
      <RouteWithLayout
        component={AddUserView}
        exact
        layout={MainLayout}
        path="/add-user"
      />
      <RouteWithLayout
        component={BasepriceView}
        exact
        layout={MainLayout}
        path="/baseprice"
      />
      <RouteWithLayout
        component={UserAdminView}
        exact
        layout={MainLayout}
        path="/user-admin"
      />
      <RouteWithLayout
        component={PlatformListView}
        exact
        layout={MainLayout}
        path="/platform"
      />
      <RouteWithLayout
        component={ClientListView}
        exact
        layout={MainLayout}
        path="/client"
      />
      <RouteWithLayout
        component={SystemSettingView}
        exact
        layout={MainLayout}
        path="/system-setting"
      />   
       <RouteWithLayout
        component={MappingUserView}
        exact
        layout={MainLayout}
        path="/mapping-user"
      />    
      <RouteWithLayout
        component={InterestView}
        exact
        layout={MainLayout}
        path="/interest"
      />
      <RouteWithLayout
        component={CampaignView}
        exact
        layout={MainLayout}
        path="/campaign"
      />
      <RouteWithLayout
        component={CampaignInfluencerView}
        exact
        layout={MainLayout}
        path="/campaign-influencer"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      
      <Route
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
