import Axios from 'axios'
import { API, UserToken } from '../../config'

import { 
    FETCH_INTEREST_REQUEST, 
    FETCH_INTEREST_SUCCESS, 
    FETCH_INTEREST_FAILED,
    ADD_INTEREST_REQUEST,
    ADD_INTEREST_FAILED,
    UPDATE_INTEREST_REQUEST,
    UPDATE_INTEREST_FAILED,
    DELETE_INTEREST_REQUEST,
    DELETE_INTEREST_FAILED
  
} from './InterestTypes'


const fetchInterestRequest = () => {
    return {
        type: FETCH_INTEREST_REQUEST
    }
}

const fetchInterestSuccess = ( data ) => {
    return {
        type: FETCH_INTEREST_SUCCESS,
        payload: data
    }
}

const fetchInterestFailed = ( message ) => {
    return {
        type: FETCH_INTEREST_FAILED,
        payload: message
    }
}

export const fetchInterest = () => {

    return (
        ( dispatch ) => {
            dispatch(fetchInterestRequest())
            Axios.get(`${API}/admin/interest/raw`, {
                
                headers: {                    
                    Authorization: `Bearer ${UserToken}`
                }
            })
            .then((res) => {
                // console.log(res, 'response fetch interest')
                dispatch(fetchInterestSuccess(res.data.results))
            }).catch((err) => {
                dispatch(fetchInterestFailed(err.message))
            })
        }
    )
}



const addInterestRequest = () => {
    return {
        type: ADD_INTEREST_REQUEST
    }
}

const addInterestFailed = ( message ) => {
    return {
        type: ADD_INTEREST_FAILED,
        payload: message
    }
}

export const addInterest = ( input ) => {
    var formdata = new FormData();
    var Headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${UserToken}`
      },
    };
    formdata.append("file", input.image);
    formdata.append("name", input.name);    
    
    return (
        ( dispatch ) => {
            dispatch(addInterestRequest())
            Axios.post(`${API}/admin/interest`, formdata, Headers)
            .then((res) => {
                console.log(res ,'response add interest')
                dispatch(fetchInterestRequest())
                Axios.get(`${API}/admin/interest/raw`, {
                    headers: {                    
                        Authorization: `Bearer ${UserToken}`
                    }
                })
                .then((res) => {
                    dispatch(fetchInterestSuccess(res.data.results))
                    window.location.reload()
                }).catch((err) => {
                    dispatch(fetchInterestFailed(err.message))
                })
            }).catch((err) => {
                dispatch(addInterestFailed(err.message))
            })
        }
    )
}


const updateInterestRequest = () => {
    return {
        type: UPDATE_INTEREST_REQUEST
    }
}

const updateInterestFailed = ( message ) => {
    return {
        type: UPDATE_INTEREST_FAILED, 
        payload: message
    }
}

export const updateInterest = ( input ) => {
    // console.log(input, 'input update redux')
    let idEdit = input.idEdit
    let formdata = new FormData();
    let Options = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${UserToken}`
      },
    };
    formdata.append("file", input.image);
    formdata.append("name", input.name);    

    return (
        ( dispatch ) => {
            dispatch(updateInterestRequest())
            Axios.put(`${API}/admin/interest/${idEdit}`, formdata, Options, null ) 
            .then((res) => {                                        
                dispatch(fetchInterestRequest())
                Axios.get(`${API}/admin/interest/raw`, {
                    headers: {                    
                        Authorization: `Bearer ${UserToken}`
                    }
                })
                .then((res) => {
                    dispatch(fetchInterestSuccess(res.data.results))
                }).catch((err) => {
                    dispatch(fetchInterestFailed(err.message))
                })
            }).catch((err) => {
                dispatch(updateInterestFailed(err.message))
            })
        }
    )
}

const deleteInterestRequest = () => {
    return {
        type: DELETE_INTEREST_REQUEST
    }
}

const deleteInterestFailed = ( message ) => {
    return {
        type: DELETE_INTEREST_FAILED,
        payload: message
    }
}

export const removeInterest = ( id ) => {
    let formdata = new FormData();
    
    formdata.append("id", id);

    let Options = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${UserToken}`
        },
      };

    return(
        ( dispatch ) => {
            dispatch(deleteInterestRequest())
            Axios.post(`${API}/admin/interest/${id}`, formdata, Options, null ) // kirim object isi { id: <id hapus> }
            .then((res) => {
                dispatch(fetchInterestRequest())
                Axios.get(`${API}/admin/interest/raw`, {
                    headers: {                    
                        Authorization: `Bearer ${UserToken}`
                    }
                })
                .then((res) => {
                    dispatch(fetchInterestSuccess(res.data.results))
                }).catch((err) => {
                    dispatch(fetchInterestFailed(err.message))
                })
            }).catch((err) => {
                dispatch(deleteInterestFailed(err.message))
            })
        }
    )
}








